import React from 'react';
import { css, cx } from '@emotion/css/macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { Add, Delete, Save } from '@mui/icons-material';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { BasicForm, PureTextField, toSelectOptions } from '../formaggio';
import { Button, Heading, IconButton, Select } from '../core/components';
import { darkgrey, error } from '../ui/Core/stylesheets/colors';
import { useReportAccess } from '../auth/hooks';
import { useAPI, useToggle } from '../core/hooks';
import { useReportContext } from './context/ReportContext';
import { useItemContext } from './context/ItemContext';
import { RemediationPriority } from '../core/types/api';
import { DEFAULT_RFT_NA_MESSAGE } from '../customSaq/constants';
import { ALL_PAYMENT_CHANNELS, allPaymentChannelsOption } from './UserData';

const PAYMENT_CHANNEL_ID_PROP = 'paymentChannelId';

const priorityClass = css`
	display: flex;
	align-items: center;
`;

const priorityLabelClass = css`
	margin-right: 16px !important;
	display: block;
`;

function Priority({ values, changeReason }: any) {
	return (
		<div className={priorityClass}>
			<Typography variant="body1" className={priorityLabelClass}>
				Priority
			</Typography>
			<RadioGroup
				style={{ display: 'flex', flexDirection: 'row' }}
				value={String(values.priority || `${RemediationPriority.Low}`)}
				onChange={changeReason}
				name="priority"
			>
				<FormControlLabel
					value={`${RemediationPriority.Low}`}
					control={<Radio size="small" />}
					label="Low"
				/>
				<FormControlLabel
					value={`${RemediationPriority.Medium}`}
					control={<Radio size="small" />}
					label="Medium"
				/>
				<FormControlLabel
					value={`${RemediationPriority.High}`}
					control={<Radio size="small" />}
					label="High"
				/>
			</RadioGroup>
		</div>
	);
}

const reasonClass = css`
	margin-bottom: 16px;
`;

const recommendationClass = css`
	margin-bottom: 8px;
`;

function ReasonReadOnly({
	channelName,
	reason,
	resolutionRecommendation,
	priority,
	nonCompliantVariant,
}: any) {
	return (
		<div className={recommendationClass}>
			{channelName && <>{channelName} - </>}
			Reason:
			<br />
			{reason}
			{nonCompliantVariant && (
				<>
					{resolutionRecommendation && (
						<>
							<br />
							<small>Resolution Recommendation: {resolutionRecommendation}</small>
						</>
					)}
					{priority && (
						<>
							<br />
							<small>Priority: {priority}</small>
						</>
					)}
				</>
			)}
		</div>
	);
}

const channelReasonClass = css`
	padding: 8px 16px;
	border: 1px solid ${darkgrey};
	border-radius: 5px;
	margin-bottom: 8px;
`;

const channelNameClass = css`
	margin: 4px 0 16px 0;
	font-weight: 600;
`;

type ReasonProps = {
	paymentChannelName?: string;
	values: any;
	changeReasons: any;
	deleteReason: any;
	idx: number;
	canEdit?: boolean;
	nonCompliantVariant?: boolean;
	isMultipleReasons: boolean;
	isEnabled: boolean;
	channelsList: any[];
};

function Reason({
	paymentChannelName,
	values = {},
	changeReasons,
	deleteReason,
	idx,
	canEdit,
	nonCompliantVariant,
	channelsList,
	isEnabled,
	isMultipleReasons,
}: ReasonProps) {
	const changeReason = React.useCallback((e) => changeReasons(idx, e), [changeReasons, idx]);
	const deleteResponse = React.useCallback(() => deleteReason(idx), [deleteReason, idx]);

	const paymentChannelControl = React.useMemo(() => {
		if (isMultipleReasons || channelsList?.length < 2) {
			return paymentChannelName && <div className={channelNameClass}>{paymentChannelName}</div>;
		}

		return (
			<FormControl style={{ marginBottom: '0.5rem' }}>
				<InputLabel>Channel</InputLabel>
				<Select
					noEmpty
					defaultValue=""
					value={values.paymentChannelId || 'all'}
					onChange={changeReason}
					options={channelsList}
					name={PAYMENT_CHANNEL_ID_PROP}
					disabled={!isEnabled}
				/>
			</FormControl>
		);
	}, [paymentChannelName, values, changeReason, channelsList, isMultipleReasons, isEnabled]);

	if (!canEdit && values.reason)
		return <ReasonReadOnly channelName={paymentChannelName} {...values} />;

	return (
		<div className={cx(channelReasonClass)} style={{ display: 'flex' }}>
			<div style={{ width: '100%' }}>
				{paymentChannelControl}
				<PureTextField
					fullWidth
					multiline
					variant="outlined"
					name="reason"
					label="Reason"
					value={values.reason || ''}
					onChange={changeReason}
					className={reasonClass}
					size="small"
					noGutter
				/>
				{nonCompliantVariant && (
					<>
						<PureTextField
							fullWidth
							multiline
							variant="outlined"
							name="resolutionRecommendation"
							label="Resolution Recommendation"
							value={values.resolutionRecommendation || ''}
							onChange={changeReason}
							size="small"
							noGutter
							className={recommendationClass}
						/>
						<Priority values={values} changeReason={changeReason} />
					</>
				)}
			</div>
			{isMultipleReasons && isEnabled && (
				<IconButton style={{ marginTop: '25px' }} onClick={deleteResponse}>
					<Delete />
				</IconButton>
			)}
		</div>
	);
}

const reasonHeaderClass = css`
	color: firebrick !important;
	margin-bottom: 16px !important;
`;

const rootClass = css`
	padding: 8px 16px;
	border-radius: 5px;
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	min-width: 500px;
	border: 1px solid rgb(128, 0, 0);
`;

const nonCompliantBackground = css`
	background-color: rgba(192, 0, 0, 0.1);
`;
const notApplicableBackground = css`
	background-color: rgba(0, 0, 0, 0.05);
`;

const controlsClass = css`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	&:last-child {
		margin-bottom: 0;
	}
`;

const controlButtonsClass = css`
	display: flex;
	flex-direction: column;
	flex: 0 1 auto;
`;

const addResponseClass = css`
	display: flex;
	justify-content: flex-start;
	height: 30px;
	align-items: center;
	margin: 10px;
`;

type NonComplianceReasonsProps = {
	itemId?: string;
	reasons?: any[];
	setReasons: React.Dispatch<React.SetStateAction<any[] | undefined>>;
	nonCompliantVariant?: boolean; //Resolution and priority fields for this variant
	canEdit?: boolean;
	methodName: string;
	applicableClicked?: boolean;
	setApplicableClicked?: (arg: boolean) => void;
};

function ItemReasons({
	itemId,
	reasons = [],
	setReasons,
	nonCompliantVariant,
	canEdit,
	methodName,
	applicableClicked,
	setApplicableClicked,
}: NonComplianceReasonsProps) {
	const { paymentChannels } = useReportAccess();
	const { projectId, refresh } = useReportContext();
	const { itemId: iid, lists, isItemApproved } = useItemContext();
	const isEnabled = React.useMemo(() => canEdit && !isItemApproved, [canEdit, isItemApproved]);

	const [newChannel, setNewChannel] = React.useState(null);
	const isMultipleReasons = React.useMemo(() => reasons?.length > 1, [reasons]);
	const naApplicableChannels = lists.applicableChannels;

	const addNaChannelList = React.useMemo(
		() =>
			toSelectOptions(
				naApplicableChannels.filter(
					(c) => reasons.filter((r) => r.paymentChannelId === c.id).length < 1,
				),
				'id',
				'name',
			),
		[naApplicableChannels, reasons],
	);

	const defaultReasons = React.useMemo(() => {
		if (naApplicableChannels && naApplicableChannels.length !== 1) return [{ reason: 'N/A' }];
		return naApplicableChannels.map((c) => ({
			reason: c.saq != null ? c.saq.notApplicableReason ?? DEFAULT_RFT_NA_MESSAGE : 'N/A',
			paymentChannelId: c.id,
		}));
	}, [naApplicableChannels]);

	const { isOn: isSaved, toggleOff: savedOff, toggleOn: savedOn } = useToggle(true);
	const changeReasons = React.useCallback(
		(idx: number, e: any) => {
			savedOff();
			setReasons((prevState) => {
				const newState: any = [...(prevState || [])];
				let { value } = e.target;
				if (e.target.name === PAYMENT_CHANNEL_ID_PROP && value === ALL_PAYMENT_CHANNELS) {
					value = undefined;
				}
				newState[idx][e.target.name] = value;
				return newState;
			});
		},
		[savedOff, setReasons],
	);

	const deleteReason = React.useCallback(
		(idx: number) => {
			savedOff();
			setReasons((prevState) => {
				const newState: any = [...(prevState || [])];
				newState.splice(idx, 1);
				return newState;
			});
		},
		[savedOff, setReasons],
	);

	const addNaReason = React.useCallback(() => {
		savedOff();
		setReasons((prevState) => {
			const newState: any = [...(prevState || [])];
			newState.push({
				reason: 'N/A',
				paymentChannelId: newChannel,
			});
			return newState;
		});
		setNewChannel(null);
	}, [newChannel, setReasons, setNewChannel, savedOff]);

	const changeNewNaChannel = React.useCallback((e) => {
		setNewChannel(e.target.value || null);
	}, []);

	const channelsList = React.useMemo(() => {
		if ((naApplicableChannels && naApplicableChannels.length > 1) || nonCompliantVariant)
			return [allPaymentChannelsOption, ...toSelectOptions(naApplicableChannels, 'id', 'name')];
		if (naApplicableChannels) return [...toSelectOptions(naApplicableChannels, 'id', 'name')];
		return [allPaymentChannelsOption];
	}, [naApplicableChannels, nonCompliantVariant]);

	const render = React.useMemo(
		() => ({
			hasAllPaymentChannels: reasons?.some(({ paymentChannelId }) => !paymentChannelId),
			list: reasons?.map((reason, idx) => (
				<>
					<Reason
						key={idx}
						idx={idx}
						paymentChannelName={
							reason.paymentChannelId
								? paymentChannels.filter(({ id }) => id === reason.paymentChannelId)[0]?.name
								: 'All Channels'
						}
						values={reason}
						changeReasons={changeReasons}
						deleteReason={deleteReason}
						canEdit={canEdit}
						nonCompliantVariant={nonCompliantVariant}
						channelsList={channelsList}
						isMultipleReasons={isMultipleReasons}
						isEnabled={isEnabled}
					/>
				</>
			)),
		}),
		[
			changeReasons,
			canEdit,
			reasons,
			nonCompliantVariant,
			paymentChannels,
			channelsList,
			deleteReason,
			isMultipleReasons,
			isEnabled,
		],
	);

	const fetchProps: any = {
		query: `RocItemControllerNew/${methodName}/${projectId}/${itemId || iid}`,
		method: 'POST',
	};

	const { initialFetch } = useAPI({ props: { ...fetchProps, params: {}, onSuccess: refresh } });
	const deleteReasons = React.useCallback(() => {
		initialFetch();
		setReasons(undefined);
	}, [initialFetch, setReasons]);

	React.useEffect(() => {
		if (applicableClicked) {
			setReasons(defaultReasons);
			initialFetch({ params: { reasons: defaultReasons } });
			setApplicableClicked(false);
		}
	}, [applicableClicked, defaultReasons, initialFetch, setApplicableClicked, setReasons]);

	React.useEffect(() => {
		setNewChannel(
			addNaChannelList && addNaChannelList.length > 0 ? addNaChannelList[0].value : null,
		);
	}, [addNaChannelList]);

	const addNewResponseControl = React.useMemo(
		() =>
			addNaChannelList &&
			addNaChannelList.length > 0 &&
			reasons?.length > 0 &&
			reasons[0].paymentChannelId != null && (
				<div className={addResponseClass}>
					<Button
						startIcon={<Add />}
						color="primary"
						size="small"
						style={{ marginTop: '20px' }}
						onClick={addNaReason}
						disabled={!isEnabled}
					>
						Add N/A Reason
					</Button>
					<FormControl style={{ marginLeft: '0.5rem' }}>
						<InputLabel>Channel</InputLabel>
						<Select
							noEmpty
							defaultValue=""
							value={newChannel || addNaChannelList[0].value}
							onChange={changeNewNaChannel}
							options={addNaChannelList}
							disabled={!isEnabled}
						/>
					</FormControl>
				</div>
			),
		[changeNewNaChannel, newChannel, addNaReason, addNaChannelList, reasons, isEnabled],
	);

	return (
		reasons &&
		reasons.length > 0 && (
			<BasicForm
				fetchConfig={{
					...fetchProps,
					onSuccess: () => {
						refresh();
						savedOn();
					},
				}}
				className={cx(
					rootClass,
					nonCompliantVariant ? nonCompliantBackground : notApplicableBackground,
				)}
				additional={{ reasons }}
			>
				<div className={controlsClass}>
					<Heading className={reasonHeaderClass}>
						{nonCompliantVariant ? 'Non-compliant' : 'N/A'}
					</Heading>
					{render.list}
					{addNewResponseControl}
				</div>
				{canEdit && !isItemApproved && (
					<div className={controlButtonsClass}>
						<IconButton color="primary" type="submit" disabled={isSaved}>
							<Save />
						</IconButton>
						<IconButton onClick={deleteReasons}>
							<Delete htmlColor={error} />
						</IconButton>
					</div>
				)}
			</BasicForm>
		)
	);
}

export default function ItemReasonsWrapper(props: NonComplianceReasonsProps) {
	const { paymentChannels } = useReportAccess();

	if (!paymentChannels) return null;

	return <ItemReasons {...props} />;
}
