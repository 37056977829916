import { css } from '@emotion/css/macro';
import { CheckboxField } from '../formaggio';
import { ResponsibilityAreaSelectorConnected } from '../contact/components/ResponsibilityAreaSelector';

const areaClass = css`
	margin-top: 8px;
`;

export default function ClientUserFormFields() {
	return (
		<>
			<CheckboxField name="isISA" label="Internal Security Assessor" />
			<ResponsibilityAreaSelectorConnected name="responsibilityArea" className={areaClass} />
		</>
	);
}
