import { connect } from 'formik';
import React from 'react';
import { get } from 'lodash';
import MaterialTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { css, cx } from '@emotion/css/macro';
import { Chip } from '@mui/material';
import { FormaggioInputProps } from '../types';
import { ErrorMessage, PasswordStrength } from '../../core/components';
import { fieldBaseClass } from '../stylesheets';
import { convertToHumanTime } from '../../core/helpers';
import { currentTimeZone, TIME_FORMAT } from '../../core/constants/time';
import QuickSnippets, { QuickSnippetsConfig } from '../../snippets/QuickSnippets';

export const urlRegexp = /^((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/gi;

const phoneRegexp = /^\+[0-9() -]{10,}$|^[0-9() -]{5,}$/;
const phoneBadRegexp = /[^0-9() \-+]|[-+ ]{2,}|\({2,}|\){2,}/;

const phoneWarnClass = css`
	margin-top: 4px;
`;

function PhoneWarning({ value = '' }: any) {
	if (!value || (value.match(phoneRegexp) && !value.match(phoneBadRegexp))) return null;

	return (
		<Chip
			label="Make sure the number is correct"
			color="warning"
			size="small"
			className={phoneWarnClass}
		/>
	);
}

const PhoneWarningWrapper = React.memo(PhoneWarning);

type NewTextFieldProps = TextFieldProps &
	Omit<FormaggioInputProps, 'storage'> & {
		name: string;
		value?: string;
		onChange: any;
		isNewPasswordField?: boolean;
		maxLength?: number;
		quickSnippets?: QuickSnippetsConfig;
		additionalChildren?: React.ReactNode;
	} & any;

export function PureTextField({
	name,
	className,
	errorMessage,
	type,
	value = '',
	noGutter,
	onChange,
	isNewPasswordField,
	maxLength,
	quickSnippets,
	additionalChildren,
	...rest
}: NewTextFieldProps) {
	const newValue = React.useMemo(() => {
		if (type === 'datetime-local') return convertToHumanTime(value, TIME_FORMAT.DATETIME_LOCAL);
		return value;
	}, [type, value]);

	const newChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (!onChange) return;

			const inputValue = e.target.value || '';

			if (type === 'datetime-local')
				try {
					onChange({
						target: {
							name,
							value: convertToHumanTime(inputValue, TIME_FORMAT.DATETIME_LOCAL) + currentTimeZone,
						},
					});
				} catch {
					//
				}

			onChange(e);
		},
		[type, onChange, name],
	);

	return (
		<div className={cx(!noGutter && fieldBaseClass, className)}>
			<MaterialTextField
				value={newValue || ''}
				name={name}
				error={!!errorMessage}
				type={type}
				onChange={newChange}
				{...{
					inputProps: {
						...rest.inputProps,
						maxLength,
					},
					InputProps: {
						...rest.InputProps,
					},
					...rest,
				}}
			/>
			{type === 'tel' && <PhoneWarningWrapper value={value} />}
			{isNewPasswordField && <PasswordStrength password={value || ''} />}
			{!!quickSnippets && !rest.disabled && (
				<QuickSnippets {...quickSnippets} name={name} value={newValue} onChange={newChange} />
			)}
			{additionalChildren}
			<ErrorMessage message={errorMessage} />
		</div>
	);
}

function TextFieldConnected({ formik, name, ...rest }: NewTextFieldProps) {
	return (
		<PureTextField
			value={get(formik.values, name, '') || ''}
			onBlur={formik.handleBlur}
			errorMessage={formik.errors[name]}
			name={name}
			{...rest}
			onChange={formik.handleChange}
		/>
	);
}

export default connect(TextFieldConnected);
