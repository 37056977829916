import { Search } from '@mui/icons-material';
import { Button, RouterLink } from '../core/components';
import { idsToProjectPath } from '../project/helpers';
import { getInterviewsLink } from '../interviews/helpers';

function ItemLinkBase({ to, label }: { to: string; label: string }) {
	return (
		<RouterLink to={to}>
			<Button startIcon={<Search />} size="small" color="primary" variant="outlined">
				{label}
			</Button>
		</RouterLink>
	);
}

export function InterviewLink({ projectId, id }: { projectId: string; id: string }) {
	return <ItemLinkBase to={getInterviewsLink(projectId, id)} label="Interview" />;
}

type ItemLinkProps = { chapter?: string; projectId: string; rocItemId: string; chapterId: string };

export default function ItemLink({
	chapter = '',
	projectId = '',
	chapterId,
	rocItemId,
}: ItemLinkProps) {
	if (!chapter) return null;

	return <ItemLinkBase to={idsToProjectPath(projectId, chapterId, rocItemId)} label={chapter} />;
}
