import React from 'react';
import { UploadFile } from '@mui/icons-material';
import { useAllowedMethods } from '../../../auth/hooks';
import { useReportContext } from '../../context/ReportContext';
import { useItemContext } from '../../context/ItemContext';
import { useToggle } from '../../../core/hooks';
import MaterialFlyOut from '../../../flyout/components/MaterialFlyOut';
import EvidenceCreate from '../../../evidence/components/EvidenceCreate';
import { ALL_PAYMENT_CHANNELS } from '../../UserData';
import FolderTile from '../../../evidence/components/FolderTile';
import { MediaFile } from '../../../core/types/api';
import SimplifiedButton from '../../simplifiedControls/SimplifiedButton';
import SimplifiedTextField from '../../simplifiedControls/SimplifiedTextField';

function MediaFileNewInput({ onFocus = () => undefined, additional }: any) {
	const { 'Evidences/Create': canAddEvidences } = useAllowedMethods();
	const { projectId, refresh } = useReportContext();
	const { itemId } = useItemContext();

	const { isOn: isEvidenceOn, toggleOn: evidenceOn, toggleOff: evidenceOff } = useToggle();

	return (
		<>
			{canAddEvidences && (
				<SimplifiedButton
					startIcon={<UploadFile />}
					onClick={(e) => {
						evidenceOn();
						onFocus(e);
					}}
					onMouseOver={onFocus}
					onFocus={onFocus}
				>
					Attach File
				</SimplifiedButton>
			)}
			<MaterialFlyOut open={isEvidenceOn} onClose={evidenceOff} title="Attachment">
				<EvidenceCreate
					onCompleted={() => {
						evidenceOff();
						refresh();
					}}
					modelId={projectId}
					itemId={itemId}
					paymentChannelId={
						additional?.PCId === ALL_PAYMENT_CHANNELS ? undefined : additional?.PCId
					}
					accept={additional?.accept?.split(',')}
				/>
			</MaterialFlyOut>
		</>
	);
}

function MediaFileExistingInput({ onChange, value = {}, disabled }: any) {
	const { projectId, attachments = [] } = useReportContext();
	const { itemId } = useItemContext();

	const attachment = React.useMemo(
		() => attachments.filter(({ id }) => id === value.attachmentId)[0] || {},
		[attachments, value.attachmentId],
	);

	const changeValue = React.useCallback(
		(newValue: any) =>
			onChange({ attachmentId: value.attachmentId, evidenceLocation: newValue.evidenceLocation }),
		[value.attachmentId, onChange],
	);

	const handleDescriptionField = React.useCallback(
		(e) => {
			changeValue({ evidenceLocation: e.target.value });
		},
		[changeValue],
	);

	if (!projectId) return null;

	return (
		<FolderTile modelId={projectId} itemId={itemId} {...(attachment as MediaFile)} viewableUsages>
			<SimplifiedTextField
				label="Evidence Location"
				onChange={handleDescriptionField}
				value={value.evidenceLocation}
				disabled={disabled}
				isTextArea
			/>
		</FolderTile>
	);
}

export default function MediaFileInput({ additional, ...rest }: any) {
	return additional.isNewMode ? (
		<MediaFileNewInput additional={additional} {...rest} />
	) : (
		<MediaFileExistingInput additional={additional} {...rest} />
	);
}
