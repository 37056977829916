import React from 'react';
import { css } from '@emotion/css/macro';
import { showDocument } from './helpers';
import SimpleLink from '../../components/SimpleLink';

export function DraftTitledLink({ contentState, entityKey, children }: any) {
	const { url } = contentState.getEntity(entityKey).getData();
	return <a href={url}>{children}</a>;
}

const imageClass = css`
	max-width: 400px;
	max-height: 300px;
	padding: 4px;
	cursor: pointer;
`;

export function DraftAtomic({ contentState, block }: any) {
	const entity = contentState.getEntity(block.getEntityAt(0));
	const { src = '' } = entity.getData();
	const type = entity.getType();
	const image = React.useMemo(() => {
		const isBase = src.startsWith('data:');
		const clickHandler = isBase ? () => showDocument(src) : () => undefined;

		return {
			isBase,
			component: (
				<img className={imageClass} alt="" src={src} onClick={clickHandler} role="presentation" />
			),
		};
	}, [src]);

	return type === 'IMAGE' ? (
		<div>
			{image.isBase ? (
				image.component
			) : (
				<SimpleLink href={src} target="_blank">
					{image.component}
				</SimpleLink>
			)}
		</div>
	) : (
		<div />
	);
}
