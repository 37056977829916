import { Chip } from '@mui/material';
import { css } from '@emotion/css/macro';

const isaClass = css`
	margin-left: 8px;
`;

export default function IsaBadge() {
	return <Chip className={isaClass} label="ISA" size="small" color="primary" variant="outlined" />;
}
