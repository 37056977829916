import React from 'react';
import { useDispatch } from 'react-redux';
import FormBase, { FormBaseProps } from './FormBase';
import upload, { UploadMethod } from '../../../files/upload';
import { UploadState } from '../../../files/UploadProgress';
import { UploadStates } from '../../../files/constants';
import ProgressWithLabel from '../../../core/components/ProgressWithLabel';

type FileFormProps = FormBaseProps & {
	url: string;
	onUploadCompleted?: (result: any, bag: any) => void;
	maxUploadSizeMb?: number;
	onError?: (result: any) => void;
	method?: UploadMethod;
};

/**
 * Sends "multipart/form-data"
 * @constructor
 */
export default function FileForm({
	children,
	onSubmit,
	url,
	onUploadCompleted,
	transformValues,
	additional,
	noResetOnSuccess,
	maxUploadSizeMb = 0,
	onError,
	method,
	...rest
}: FileFormProps) {
	const dispatch = useDispatch();

	const [uploadState, setUploadState] = React.useState<UploadState>({
		state: UploadStates.Waiting,
		percentage: 0,
	});

	const submit = React.useCallback(
		(values, bag) => {
			const allValues = { ...values, ...additional };
			const transformedValues = transformValues ? transformValues(allValues) : allValues;
			if (onSubmit) onSubmit(transformedValues, bag);
			else {
				upload({
					dispatch,
					setUploadState,
					values: transformedValues,
					url: `api/${url}`,
					maxUploadSizeMb,
					method,
				})
					.then((result) => {
						if (onUploadCompleted) onUploadCompleted(result, bag);
						if (!noResetOnSuccess) bag.resetForm();
					})
					.catch((result) => {
						if (onError) onError(result);
					});
			}
		},
		[
			additional,
			transformValues,
			onSubmit,
			dispatch,
			url,
			maxUploadSizeMb,
			method,
			onUploadCompleted,
			noResetOnSuccess,
			onError,
		],
	);

	return (
		<FormBase {...rest} onSubmit={submit}>
			{children}
			{uploadState.state === UploadStates.Uploading && (
				<ProgressWithLabel value={uploadState.percentage} />
			)}
		</FormBase>
	);
}
