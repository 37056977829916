import { Resource } from '../../../core/types/api';
import WorkflowLinkedInterview from './WorkflowLinkedInterview';

export type WorkflowLinkedDataShared = { id: string };

type WorkflowLinkedDataProps = { type: Resource } & WorkflowLinkedDataShared;

export default function WorkflowLinkedData({ id, type }: WorkflowLinkedDataProps) {
	let Component = null;

	switch (type) {
		case Resource.Interview:
			Component = WorkflowLinkedInterview;
			break;
		default:
			break;
	}

	return Component ? <Component id={id} /> : null;
}
