import React from 'react';
import { AtomicBlockUtils, convertToRaw, EditorState, Modifier, RichUtils } from 'draft-js';
import { css, cx } from '@emotion/css/macro';
import { Box, IconButton } from '@mui/material';
import {
	Code,
	FormatBold,
	FormatItalic,
	FormatListBulleted,
	FormatListNumbered,
	FormatQuote,
	FormatUnderlined,
	Image,
	Link,
	Redo,
	Undo,
} from '@mui/icons-material';
import { defaultSuggestionsFilter, MentionData } from '@draft-js-plugins/mention';
import { DraftControlButton } from './types';
import PureDraftEditor from './PureDraftEditor';
import DraftQuickImage from './DraftQuickImage';
import { darkgrey } from '../../stylesheets/constants';
import { useToggle } from '../../hooks/useToggle';

function isValidQuickImage(urlOrBase?: string) {
	return (
		!!urlOrBase.trim() &&
		urlOrBase.length > 10 &&
		(urlOrBase.startsWith('data:image') ||
			urlOrBase.endsWith('.jpg') ||
			urlOrBase.endsWith('.jpeg') ||
			urlOrBase.endsWith('.png'))
	);
}

function setTextFormat(setEditorState: any, style: string) {
	setEditorState((prevState) => RichUtils.toggleInlineStyle(prevState, style));
}

function setBlockFormat(setEditorState: any, style: string) {
	setEditorState((prevState) => RichUtils.toggleBlockType(prevState, style));
}

const controlPanel: DraftControlButton[] = [
	{
		icon: <Undo />,
		callback: ({ setEditorState }) => setEditorState((prevState) => EditorState.undo(prevState)),
	},
	{
		icon: <Redo />,
		callback: ({ setEditorState }) => setEditorState((prevState) => EditorState.redo(prevState)),
	},
	{
		icon: <FormatBold />,
		callback: ({ setEditorState }) => setTextFormat(setEditorState, 'BOLD'),
	},
	{
		icon: <FormatItalic />,
		callback: ({ setEditorState }) => setTextFormat(setEditorState, 'ITALIC'),
	},
	{
		icon: <FormatUnderlined />,
		callback: ({ setEditorState }) => setTextFormat(setEditorState, 'UNDERLINE'),
	},
	{
		icon: <Code />,
		callback: ({ setEditorState }) => setBlockFormat(setEditorState, 'code-block'),
	},
	{
		icon: <FormatQuote />,
		callback: ({ setEditorState }) => setBlockFormat(setEditorState, 'blockquote'),
	},
	{
		icon: <FormatListBulleted />,
		callback: ({ setEditorState }) => setBlockFormat(setEditorState, 'unordered-list-item'),
	},
	{
		icon: <FormatListNumbered />,
		callback: ({ setEditorState }) => setBlockFormat(setEditorState, 'ordered-list-item'),
	},
	{
		icon: <Link />,
		callback: ({ setEditorState }) => {
			// eslint-disable-next-line no-alert -- Plain for now
			const url = prompt('Paste URL here to wrap current selection with') || '';
			if (!url.trim()) return;

			setEditorState((prevState) => {
				const contentWithNewUrl = prevState
					.getCurrentContent()
					.createEntity('LINK', 'MUTABLE', { url });

				return EditorState.push(
					prevState,
					Modifier.applyEntity(
						contentWithNewUrl,
						prevState.getSelection(),
						contentWithNewUrl.getLastCreatedEntityKey(),
					),
					'change-block-data',
				);
			});
		},
		disabledState: (editorState) =>
			editorState.getSelection().getStartOffset() === editorState.getSelection().getEndOffset(),
	},
];

export const draftEditorSharedClass = css`
	line-height: 22px;
	background: white;
	overflow: auto;
`;

const editorClass = css`
	border: 1px solid ${darkgrey};
	padding: 8px;
	box-shadow: inset 0px 1px 8px -3px ${darkgrey};
	border-radius: 5px;
`;

export type DraftFieldProps = {
	setEditorState: any;
	mentions?: MentionData[];
	className?: string;
	name?: string;
	placeholder?: string;
	editorState?: EditorState;
	readOnly?: boolean;
	editorKey: string;
};

const DEBUG = false;

export default function DraftField({
	mentions = [],
	editorState = EditorState.createEmpty(),
	setEditorState,
	className,
	name,
	placeholder,
	editorKey,
	readOnly,
}: DraftFieldProps) {
	const [open, setOpen] = React.useState(false);
	const [suggestions, setSuggestions] = React.useState(mentions);

	const onOpenChange = React.useCallback((arg: boolean) => {
		setOpen(arg);
	}, []);

	const onSearchChange = React.useCallback(
		({ value }: { value: string }) => {
			setSuggestions(defaultSuggestionsFilter(value, mentions));
		},
		[mentions],
	);

	const renderControls = React.useMemo(
		() =>
			controlPanel.map(({ icon, callback, disabledState }, idx) => (
				<IconButton
					size="small"
					key={idx}
					onClick={() => {
						callback({ setEditorState });
					}}
					disabled={disabledState ? disabledState(editorState) : false}
				>
					{icon}
				</IconButton>
			)),
		[editorState, setEditorState],
	);

	const { isOn: imageOpen, toggle: toggleImage, toggleOff: closeImage } = useToggle();
	const attachImageOrUrl = React.useCallback(
		(url = '') => {
			if (!isValidQuickImage(url)) {
				// eslint-disable-next-line no-alert -- Ok
				alert('File/URL is invalid, too short, or is incorrect format');
				return;
			}

			setEditorState((prevState) => {
				const contentStateWithEntity = prevState
					.getCurrentContent()
					.createEntity('IMAGE', 'IMMUTABLE', {
						src: url,
					});
				const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

				return AtomicBlockUtils.insertAtomicBlock(prevState, entityKey, ' ');
			});

			closeImage();
		},
		[closeImage, setEditorState],
	);

	return (
		<Box>
			<Box
				display="flex"
				flexWrap="wrap"
				alignItems="center"
				position="sticky"
				bgcolor="white"
				top={0}
				left={0}
				p="2px"
			>
				{renderControls}
				<IconButton onClick={toggleImage}>
					<Image />
				</IconButton>
				<DraftQuickImage isOpen={imageOpen} sendImageOrUrl={attachImageOrUrl} />
			</Box>
			<div className={cx(draftEditorSharedClass, editorClass, className)}>
				<PureDraftEditor
					editorState={editorState}
					onChange={setEditorState}
					webDriverTestID={name}
					placeholder={placeholder}
					editorKey={editorKey}
					mentionsConfig={{
						open,
						suggestions,
						onOpenChange,
						onSearchChange,
					}}
					readOnly={readOnly}
				/>
			</div>
			{DEBUG && (
				<Box width="400px" whiteSpace="pre-line">
					<pre>{JSON.stringify(convertToRaw(editorState.getCurrentContent()), null, ' ')}</pre>
				</Box>
			)}
		</Box>
	);
}
