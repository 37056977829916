import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { DeleteForever, UploadFile } from '@mui/icons-material';
import { useAllowedMethods } from '../../auth/hooks';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { useAPI, useToggle } from '../../core/hooks';
import { DownloadState } from '../../../shared/modules/files/constants';
import { downloadFile } from '../../files/helpers';
import { Button, IconButton } from '../../core/components';
import { TableStickyCell } from '../../../shared/components';
import EvidenceList from '../../evidence/components/EvidenceList';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import EvidenceCreate from '../../evidence/components/EvidenceCreate';
import { AttachmentRowProps } from '../AttachmentRow';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { getSampleSetRowSpan } from './ProjectSampleSets';
import { FileExtMimes } from '../../files/constants';

export default function SampleSetRowControls({
	refreshProjectAttachments,
	attachment,
}: AttachmentRowProps) {
	const {
		'SampleSets/GenerateTrackingSheet': canGenerateTrackingSheet,
		'SampleSets/UploadTrackingSheet': canUploadTrackingSheet,
		'SampleSets/DeleteTrackingSheet': canDeleteTrackingSheet,
	} = useAllowedMethods();
	const { projectId, projectInfo, refresh: refreshReport } = useReportContext();
	const { itemId: iid } = useItemContext();
	const { fetchAPI } = useAPI();
	const { projectName } = projectInfo;

	const [downloadTrackingSheetState, setDownloadTrackingSheetState] = React.useState<DownloadState>(
		DownloadState.Idle,
	);
	const generateTrackingSheet = React.useCallback(
		() =>
			downloadFile({
				setDownloadState: setDownloadTrackingSheetState,
				query: `SampleSets/GenerateTrackingSheet/${projectId}/${attachment?.id}`,
				fileName: `${projectName} - ${attachment?.description} - Sample Set Tracking Sheet.xlsx`,
			}),
		[
			projectId,
			projectName,
			setDownloadTrackingSheetState,
			attachment?.description,
			attachment?.id,
		],
	);

	const rowSpan = React.useMemo(() => getSampleSetRowSpan(attachment), [attachment]);

	//Tracking sheets

	const { isOn, toggleOff, toggleOn } = useToggle();

	const FileControls = React.useCallback(
		({ id }: any) => {
			const deleteFile = () =>
				fetchAPI({
					query: `SampleSets/DeleteTrackingSheet/${projectId}/${id}`,
					method: 'DELETE',
					onSuccess: () => {
						if (refreshProjectAttachments) refreshProjectAttachments();
					},
					confirmation: true,
				});

			if (!canDeleteTrackingSheet) return null;

			return (
				<IconButton onClick={deleteFile} size="small">
					<DeleteForever />
				</IconButton>
			);
		},
		[canDeleteTrackingSheet, fetchAPI, projectId, refreshProjectAttachments],
	);

	return (
		<>
			{canGenerateTrackingSheet && (
				<TableStickyCell rowSpan={rowSpan}>
					<ButtonGroup color="primary">
						<Button
							disabled={downloadTrackingSheetState === DownloadState.Downloading}
							onClick={generateTrackingSheet}
						>
							{downloadTrackingSheetState === DownloadState.Downloading
								? 'Generating...'
								: 'Generate Tracking Sheet'}
						</Button>
						{canUploadTrackingSheet && (
							<Button startIcon={<UploadFile />} onClick={toggleOn}>
								Upload
							</Button>
						)}
					</ButtonGroup>
					<MaterialFlyOut open={isOn} onClose={toggleOff} title="Attachment">
						<EvidenceCreate
							onCompleted={() => {
								toggleOff();
								refreshProjectAttachments();
							}}
							modelId={projectId}
							itemId={iid}
							accept={[FileExtMimes.Xls, FileExtMimes.Xlsx]}
							isSampleSetTrackingSheet
						/>
					</MaterialFlyOut>
				</TableStickyCell>
			)}
			<TableStickyCell rowSpan={rowSpan}>
				<EvidenceList
					refresh={refreshReport}
					modelId={projectId}
					itemId={iid}
					attachments={attachment?.trackingSheets}
					additionalControls={FileControls}
				/>
			</TableStickyCell>
		</>
	);
}
