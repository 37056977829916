import React from 'react';
import { css } from '@emotion/css/macro';
import { isFuture, isPast } from 'date-fns';
import { CalendarMonth, Place } from '@mui/icons-material';
import { Box } from '@mui/material';
import { InterviewModel } from '../core/types/api';
import { FieldSet, RouterLink } from '../core/components';
import { ModelItemIdProps } from './types';
import { darkgrey, highlight } from '../ui/Core/stylesheets/colors';
import { convertToHumanTime } from '../core/helpers';
import { getInterviewsLink } from '../interviews/helpers';

const menuSubItemClass = css`
	margin-right: 1rem;
	font-size: 14px !important;
`;

const subItemIconClass = css`
	margin-right: 0.5rem;
`;

const listClass = css`
	display: flex;
	flex-wrap: wrap;
`;

type InterviewMenuItemProps = InterviewModel & ModelItemIdProps;

function InterviewMenuItem({ modelId, id, subject, date, location }: InterviewMenuItemProps) {
	const link = React.useMemo(() => getInterviewsLink(modelId, id), [modelId, id]);

	return (
		<RouterLink to={link}>
			<Box display="flex" alignItems="center">
				<CalendarMonth htmlColor={darkgrey} className={subItemIconClass} />
				{convertToHumanTime(date)}
			</Box>
			{location && (
				<div className={menuSubItemClass}>
					<Place htmlColor={darkgrey} className={subItemIconClass} />
					{location}
				</div>
			)}
			<div className={menuSubItemClass}>Subject: {subject}</div>
		</RouterLink>
	);
}

type InterviewsButtonMenuProps = ModelItemIdProps & { interviews?: InterviewModel[] };

export function InterviewsButtonMenu({ modelId, itemId, interviews }: InterviewsButtonMenuProps) {
	const renderInterviewMenuItems = React.useMemo(
		() => ({
			scheduled:
				interviews
					?.filter(({ date }) => isFuture(new Date(date)))
					.map((interview, idx) => (
						<InterviewMenuItem modelId={modelId} itemId={itemId} {...interview} key={idx} />
					)) || [],
			passed:
				interviews
					?.filter(({ date }) => isPast(new Date(date)))
					.map((interview, idx) => (
						<InterviewMenuItem modelId={modelId} itemId={itemId} {...interview} key={idx} />
					)) || [],
		}),
		[interviews, modelId, itemId],
	);

	if (!interviews || interviews.length === 0) return null;

	return (
		<>
			{renderInterviewMenuItems.scheduled.length > 0 && (
				<FieldSet legend="Scheduled interviews" legendColor={highlight} className={listClass}>
					{renderInterviewMenuItems.scheduled}
				</FieldSet>
			)}
			{renderInterviewMenuItems.passed.length > 0 && (
				<FieldSet legend="Past interviews" className={listClass}>
					{renderInterviewMenuItems.passed}
				</FieldSet>
			)}
		</>
	);
}
