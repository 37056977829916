import { LicenseStatus } from '../hubs/LicenseStatus';
import { FeatureDetail } from '../core/types/api';

export enum FeatureFlag {
	DowngradePci321r2To321r1 = 'downgrade.pci.321r2_to_321r1',
	DowngradePci40r1r2To40r1 = 'downgrade.pci.40r1r2_to_40r1',
	PciScopeBuilder = 'pci.scope.builder',
	ReportTypePci401 = 'report.type.pci_4_0_1',
	ProjectFullClone = 'project.full_clone',
	WorkflowMoveToQSA = 'workflow.move_to_qsa',
}

export type FeatureFlagDetails = Partial<Record<string, FeatureDetail>>;

export function isFeatureEnabled(license: LicenseStatus, feature: FeatureFlag) {
	return license && license.features && license.features.indexOf(String(feature)) >= 0;
}

/**
 * Use this if license argument can't be passed from hub.
 * Make sure that it's not used by a const,
 * otherwise it'll return only the first state on page load.
 */
export function isFeatureEnabledLazy(feature: FeatureFlag) {
	return window && window.MQSA_FEATURES && window.MQSA_FEATURES.indexOf(feature) >= 0;
}

// Put helpers to isFeatureEnabledLazy below to avoid unordered shadow importing and crashing

export const isScopeBuilderEnabled = () => isFeatureEnabledLazy(FeatureFlag.PciScopeBuilder);
export const isPciTemplate401Enabled = () => isFeatureEnabledLazy(FeatureFlag.ReportTypePci401);
export const isProjectFullCloneEnabled = () => isFeatureEnabledLazy(FeatureFlag.ProjectFullClone);
