import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MaterialTextField from '@material-ui/core/TextField';
import { addWeeks } from 'date-fns';
import { css } from '@emotion/css/macro';
import { Add, ContactPage, Delete, Person } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { Roles, ShortUserInfo } from '../core/types/api';
import { Contact } from '../contact/types';
import { error, mainDark, warning } from '../ui/Core/stylesheets/colors';
import { Button, IconButton } from '../core/components';
import usePopup from '../popup/hooks/usePopup';
import { extractObjectIds } from '../core/utils/helpers';
import { BasicForm, DateTimeField } from '../formaggio';
import { ModelItemIdProps } from '../rocTemplates/types';
import { ResponsibilityAreaSelector } from '../contact/components/ResponsibilityAreaSelector';
import MaterialFlyOut from '../flyout/components/MaterialFlyOut';
import { ResponsibilitiesTooltipIcon } from '../../features/Interviews/ResponsibilitiesToolTip';
import RequesteeDialog from './RequesteeDialog';
import { listToString } from '../core/helpers';
import PrimaryContactStar from '../contact/components/PrimaryContactStar';

type ShortUserInfoWithId = Partial<ShortUserInfo> & { id: string };

export type Requestee = ShortUserInfoWithId &
	Contact & { isUser: boolean; title?: string; projectRole?: Roles; itemResponsibility?: number };

export function RequesteeWithDesc({
	organization,
	title,
	jobTitle,
	isPrimary,
	isISA,
	isUser,
	responsibilityArea,
	name,
	email,
	projectRole,
}: Requestee) {
	const desc = listToString(
		[organization, title, jobTitle, isISA && 'ISA', isUser && 'User'],
		' / ',
	);
	const Icon = isUser ? Person : ContactPage;

	return (
		<Box display="flex" alignItems="center">
			<Icon style={{ marginRight: '16px' }} htmlColor={mainDark} />
			<ResponsibilitiesTooltipIcon responsibilityArea={responsibilityArea} />
			<PrimaryContactStar isTrue={isPrimary || projectRole === Roles.ExecutiveClientRepresentative}>
				{name} {desc && <>({desc})</>}
			</PrimaryContactStar>
			{!email && (
				<Chip
					color="error"
					size="small"
					label="No e-mail specified"
					style={{ marginLeft: '8px' }}
				/>
			)}
		</Box>
	);
}

const OrDivider = (
	<div
		style={{
			margin: '1rem 0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}}
	>
		<Divider style={{ marginRight: '1rem', flex: '1 1 auto' }} />
		<Typography variant="subtitle1">or</Typography>
		<Divider style={{ marginLeft: '1rem', flex: '1 1 auto' }} />
	</div>
);

type RequestingFrom = { requestees: Requestee[]; removeUser: (idx: number) => void };

function RequestingFrom({ requestees, removeUser }: RequestingFrom) {
	const renderList = React.useMemo(
		() =>
			requestees.map(({ id, ...req }, idx) => {
				const remove = () => removeUser(idx);

				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							margin: '8px 0',
						}}
						key={id}
					>
						<Box>
							<RequesteeWithDesc id={id} {...req} />
						</Box>
						<IconButton onClick={remove}>
							<Delete htmlColor={error} />
						</IconButton>
					</div>
				);
			}),
		[requestees, removeUser],
	);

	if (requestees.length === 0) return null;

	return (
		<div>
			Requesting from
			{renderList}
		</div>
	);
}

const rootClass = css`
	display: flex;
	flex-direction: column;
`;

type EvidenceRequestProps = Omit<ModelItemIdProps, 'itemId'> & {
	itemResponsibility?: number;
	isOpen?: boolean;
	onClose: () => void;
	setRequest: (args: any) => void;
	alreadyRequestedIds?: string[];
};

type EmailWithResponsibility = {
	email: string;
	responsibility?: number;
};

export default function Request({
	modelId,
	itemResponsibility,
	isOpen,
	onClose,
	setRequest,
	alreadyRequestedIds = [],
}: EvidenceRequestProps) {
	const [requestees, setRequestees] = React.useState<Requestee[]>([]);
	const [emails, setEmails] = React.useState<EmailWithResponsibility[]>([]);

	const addNewEmail = () =>
		setEmails((prevList) => {
			const newList = [...prevList];
			newList.push({ email: '' });
			return newList;
		});

	const removeEmail = React.useCallback(
		(idx) =>
			setEmails((prevList) => {
				const newList = [...prevList];
				newList.splice(idx, 1);
				return newList;
			}),
		[setEmails],
	);

	const renderEmailFields = React.useMemo(
		() =>
			emails.map((emailArea, idx) => {
				const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
					e.persist();
					setEmails((prevList) => {
						const newList = [...prevList];
						newList[idx].email = e.target.value || '';
						return newList;
					});
				};
				const changeArea = (area: number) => {
					setEmails((prevList) => {
						const newList = [...prevList];
						newList[idx].responsibility = area;
						return newList;
					});
				};

				const remove = () => removeEmail(idx);
				const value = emails[idx].email;
				const emailError = alreadyRequestedIds.indexOf(value) >= 0;

				return (
					<div style={{ display: 'flex' }} key={idx}>
						<MaterialTextField
							value={value}
							onChange={changeEmail}
							type="email"
							error={emailError}
							helperText={
								emailError ? 'Response from this person was already requested' : undefined
							}
						/>
						<ResponsibilityAreaSelector
							setActualAreas={changeArea}
							initialAreas={0}
							currentAreas={emailArea.responsibility || 0}
							miniVersion
						/>
						<IconButton onClick={remove}>
							<Delete htmlColor={error} />
						</IconButton>
					</div>
				);
			}),
		[emails, setEmails, removeEmail, alreadyRequestedIds],
	);

	const { showPopup } = usePopup({ text: 'User/contact already selected', color: warning }, true);

	const removeUser = React.useCallback(
		(idx: number) =>
			setRequestees((prevList) => {
				const newList = [...prevList];
				newList.splice(idx, 1);
				return newList;
			}),
		[setRequestees],
	);

	const onChoose = (user: Requestee) => {
		if (extractObjectIds(requestees).indexOf(user.id) >= 0) return showPopup();
		return setRequestees((prevList) => {
			const newList = [...prevList];
			newList.push(user);
			return newList;
		});
	};

	const submitRequest = React.useCallback(
		(values: any) => {
			setRequest(values);
			onClose();
		},
		[setRequest, onClose],
	);

	const extractedIds = React.useMemo(() => {
		const contactIds: string[] = [];
		const userIds: string[] = [];

		requestees.forEach(({ isUser, id }) => {
			if (isUser) userIds.push(id);
			else contactIds.push(id);
		});

		return { contactIds, userIds };
	}, [requestees]);

	return (
		<MaterialFlyOut open={isOpen} onClose={onClose} title="Response Request">
			<BasicForm
				onSubmit={submitRequest}
				initialValues={{
					validUntil: addWeeks(new Date(), 1).toISOString(),
				}}
				additional={{
					emails,
					...extractedIds,
				}}
				className={rootClass}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<RequesteeDialog
						itemResponsibility={itemResponsibility}
						onChoose={onChoose}
						projectId={modelId}
						requestees={requestees}
						alreadyRequestedIds={alreadyRequestedIds}
					/>
					{OrDivider}
					Enter e-mail(s)
					{renderEmailFields}
					<div style={{ margin: '0.5rem 0' }}>
						<IconButton id="addRequestEmail" onClick={addNewEmail}>
							<Add />
						</IconButton>
					</div>
				</div>
				<Divider style={{ marginBottom: '1rem' }} />
				Contacts and e-mail addressees will be able to register a permanent account
				<Divider style={{ margin: '1rem 0' }} />
				<RequestingFrom requestees={requestees} removeUser={removeUser} />
				<DateTimeField
					label="Token expiration date"
					helperText="Default is 7 days"
					name="validUntil"
				/>
				<Button
					color="primary"
					type="submit"
					style={{ marginTop: '8px' }}
					disabled={emails.length === 0 && requestees.length === 0}
				>
					Attach Request
				</Button>
			</BasicForm>
		</MaterialFlyOut>
	);
}
