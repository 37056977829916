import { useQuery, QueryHookOptions } from '@apollo/client';
import React from 'react';
import { Channel } from '../core/types/api';
import {
	GetTemplateListQuery,
	GetTemplateListQueryVariables,
	SourceControlTemplate,
} from '../../graphql/typescript';
import { GET_TEMPLATE_LIST_QUERY } from '../../graphql/domains/model/queryList';
import { useAPI, useHistoryQS } from '../core/hooks';
import { ITEM_HIGHLIGHT_QS_KEY } from './LinkButton';
import { ReportFilterType } from './filtering/reportFilterStorage';
import { stringifyReportFilter } from './filtering/helpers';
import { ClientReplicator, EditorReplicator } from './Replicator';
import { useReportContext } from './context/ReportContext';
import { useItemContext } from './context/ItemContext';
import { useHubState } from '../hubs/hooks';
import { SCROLL_INTO_VIEW_CONFIG } from '../core/constants/scrollIntoViewConfig';

export function useReportView(
	projectId?: string,
	itemId?: string,
	SAQ?: boolean,
	reportFilter: ReportFilterType = {},
) {
	const { fetchAPI } = useAPI({});
	const [view, setView] = React.useState<any>(undefined);
	const [error, setError] = React.useState<boolean | undefined>(undefined);
	const itemIdQS = usePermalinkHighlightQS();
	const stringFilter = stringifyReportFilter({
		...reportFilter,
		...(itemIdQS ? { saq: false } : {}),
	});
	const load = React.useCallback(
		() =>
			fetchAPI({
				query: `RocItemControllerNew/report/view/${projectId}`,
				qs: {
					rocItemId: itemId,
					saq: SAQ,
					...JSON.parse(stringFilter),
				},
				onSuccess: (result: any) => {
					setView(result?.data);
					setError(false);
				},
				onError: () => setError(true),
			}),
		[projectId, itemId, fetchAPI, setError, SAQ, stringFilter],
	);

	React.useEffect(() => {
		if (projectId) load();
	}, [itemId, projectId, load, SAQ]);

	return {
		view: view || {},
		refresh: load,
		fetchAPI,
		error,
	};
}

export function useModelList(
	params?: QueryHookOptions<GetTemplateListQuery, GetTemplateListQueryVariables>,
): [SourceControlTemplate[], () => void] {
	const { data, refetch } = useQuery<GetTemplateListQuery, GetTemplateListQueryVariables>(
		GET_TEMPLATE_LIST_QUERY,
		params,
	);

	return [data?.list?.data || [], refetch];
}

export function useTemplateDetails(type: number): [SourceControlTemplate, () => void] {
	const { state, initialFetch } = useAPI({
		props: { query: `RocModelControllerNew/template/details/${type}` },
		autoLoad: true,
	});

	return [state?.autodata?.data || {}, initialFetch];
}

export function useChannelsList(projectId?: string): { list: Channel[]; refetch: () => void } {
	const { fetchAPI } = useAPI({});
	const [view, setView] = React.useState<Channel[]>(undefined);
	const load = React.useCallback(
		() =>
			fetchAPI({
				query: `Channels/view/${projectId}`,
				onSuccess: (result: any) => {
					setView(result?.data);
				},
			}),
		[fetchAPI, projectId],
	);

	React.useEffect(() => {
		if (projectId) load();
		else setView([]);
	}, [projectId, load]);

	return { list: view, refetch: load || (() => undefined) };
}

export function usePermalinkHighlightQS() {
	return useHistoryQS(ITEM_HIGHLIGHT_QS_KEY) as string;
}

export function useWorkflowOpenQS(itemId: string) {
	const idMatches = (useHistoryQS('id') as string) === itemId;
	const needsOpen = (typeof useHistoryQS('workflow') as string) !== 'undefined';

	return idMatches && needsOpen;
}

export function useScrollToReportItem<T extends Element>(itemId: string) {
	const ref = React.useRef<T>(null);

	const highlightQS = usePermalinkHighlightQS();

	React.useEffect(() => {
		if (!!highlightQS && ref.current && highlightQS === itemId)
			setTimeout(() => {
				if (ref.current) ref.current.scrollIntoView(SCROLL_INTO_VIEW_CONFIG);
			}, 200);
	}, [itemId, highlightQS]);

	return ref;
}

export function useReplicator(viewMode?: boolean) {
	return React.useMemo(() => (viewMode ? ClientReplicator : EditorReplicator), [viewMode]);
}

export function useItemStatus() {
	const { projectId } = useReportContext();
	const { itemId, status } = useItemContext();
	const [hubState] = useHubState();
	const [newStatus, setNewStatus] = React.useState(status);

	React.useEffect(() => {
		if (hubState.newItemStatus[0] === projectId && hubState.newItemStatus[1] === itemId)
			setNewStatus(hubState.newItemStatus[2]);
	}, [projectId, itemId, hubState.newItemStatus]);

	return newStatus !== status ? newStatus : status;
}
