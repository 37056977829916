import { ItemFullProgress } from './types';
import { ItemStatus } from './enums';
import { AssessmentStatus } from '../core/types/api';

function calculatePercentFromProgress(
	progress: Record<string, number>,
	total: number,
	numericKey = false,
): number | null {
	if (total === 0) return null;

	const approvedKey = numericKey
		? String(ItemStatus.Approved)
		: progressStatuses[ItemStatus.Approved].name;

	return ((progress[approvedKey] || 0) / total) * 100;
}

function calculateTotal(progress: Record<string, number>, numericKey = false): number {
	return Object.keys(progress).reduce((memo, curr) => {
		const compareKey = numericKey
			? String(ItemStatus.Ignore)
			: progressStatuses[ItemStatus.Ignore].name;

		return curr === compareKey ? memo : memo + progress[curr];
	}, 0);
}

export function calculateProgress(
	progress?: Record<string, number>,
	numericKey = false,
): ItemFullProgress {
	if (!progress) return { progress: null, percent: 0, total: 0 };

	const total = calculateTotal(progress, numericKey);
	const percent = calculatePercentFromProgress(progress, total, numericKey);

	if (percent == null) return { progress: null, percent: 0, total };

	return {
		progress,
		percent: Math.round(percent),
		total,
	};
}

export enum ProgressType {
	AssessmentProgress,
	AssessmentFindingsProgress,
}

export const progressStatuses: Record<
	ItemStatus,
	{ name: string; title?: string; tileTitle?: string; color?: string }
> = {
	[ItemStatus.NotStarted]: {
		name: 'notStarted',
		title: 'Not started',
		tileTitle: 'not started',
		color: 'gray',
	},
	[ItemStatus.WaitingForCustomer]: {
		name: 'waitingForCustomer',
		title: 'Waiting for customer',
		tileTitle: 'waiting for customer',
		color: '#be6800',
	},
	[ItemStatus.InterviewScheduled]: {
		name: 'interviewScheduled',
		title: 'Interview scheduled',
		tileTitle: 'interview(s) scheduled',
		color: 'steelblue',
	},
	[ItemStatus.WaitingForQsa]: {
		name: 'waitingForQsa',
		title: 'Waiting for QSA',
		tileTitle: 'waiting for QSA',
		color: 'tomato',
	},
	[ItemStatus.ReadyForPeerReview]: {
		name: 'readyForPeerReview',
		title: 'Ready for peer review',
		tileTitle: 'ready for peer review',
		color: 'firebrick',
	},
	[ItemStatus.ChangesRequested]: {
		name: 'changesRequested',
		title: 'Changes requested',
		tileTitle: 'changes requested',
		color: 'orangered',
	},
	[ItemStatus.Approved]: {
		name: 'approved',
		title: 'Approved',
		tileTitle: 'approved',
		color: 'darkgreen',
	},
	[ItemStatus.Ignore]: {
		name: 'ignore',
	},
	[ItemStatus.ReadyForQA]: {
		name: 'readyForQA',
		title: 'Ready for QA',
		tileTitle: 'ready for QA',
		color: 'firebrick',
	},
};

export const assessmentStatuses: Record<
	AssessmentStatus,
	{ name: string; title?: string; tileTitle?: string; color?: string }
> = {
	[AssessmentStatus.NotStarted]: {
		name: 'notStarted',
		title: 'Not started',
		tileTitle: 'not started',
		color: 'gray',
	},
	[AssessmentStatus.Started]: {
		name: 'started',
		title: 'Started',
		tileTitle: 'started',
		color: '#009999',
	},
	[AssessmentStatus.NotInPlace]: {
		name: 'notInPlace',
		title: 'Not in place',
		tileTitle: 'not in place',
		color: '#EC0033',
	},
	[AssessmentStatus.NotTested]: {
		name: 'notTested',
		title: 'Not tested',
		tileTitle: 'not tested',
		color: '#FF8240',
	},
	[AssessmentStatus.NotApplicable]: {
		name: 'notApplicable',
		title: 'Not applicable',
		tileTitle: 'not applicable',
		color: '#00CC00',
	},
	[AssessmentStatus.InPlace]: {
		name: 'inPlace',
		title: 'In place',
		tileTitle: 'in place',
		color: '#008500',
	},
};
