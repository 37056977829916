import { BRAND } from '../../../branding/constants';

export {
	darkness,
	lightgrey,
	middlegrey,
	grass,
	darkgrass,
	darkgrey,
	kveld,
	skyblue,
} from '../../../../shared/stylesheets/constants';

export const { secondary, success, warning, error, highlight, mainLight, mainDark } = BRAND.colors;
