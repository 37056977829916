import React from 'react';
import { CalendarMonth } from '@mui/icons-material';
import { css } from '@emotion/css/macro';
import { WorkflowLinkedDataShared } from './WorkflowLinkedData';
import { useReportContext } from '../../../rocTemplates/context/ReportContext';
import { RouterLink } from '../../../core/components';
import { getInterviewsLink } from '../../../interviews/helpers';
import { darkgrey } from '../../../ui/Core/stylesheets/colors';
import { convertToHumanTime } from '../../../core/helpers';

const linkClass = css`
	display: flex;
	align-items: center;
`;

type WorkflowLinkedInterviewProps = WorkflowLinkedDataShared;

export default function WorkflowLinkedInterview({ id }: WorkflowLinkedInterviewProps) {
	const { interviews, projectId } = useReportContext();

	const interview = React.useMemo(
		() => interviews?.filter((i) => i.id === id)[0],
		[id, interviews],
	);

	return interview ? (
		<RouterLink to={getInterviewsLink(projectId, interview.id)} className={linkClass}>
			<CalendarMonth htmlColor={darkgrey} />
			<span>
				{interview.subject} - {convertToHumanTime(interview.date)}
			</span>
		</RouterLink>
	) : null;
}
