import { FormatListNumbered } from '@mui/icons-material';
import { Box } from '@mui/material';
import { css, cx } from '@emotion/css/macro';
import SimplifiedButton from '../../simplifiedControls/SimplifiedButton';
import { useAPI, useToggle } from '../../../core/hooks';
import { useAllowedMethods } from '../../../auth/hooks';
import MaterialFlyOut from '../../../flyout/components/MaterialFlyOut';
import { EvidenceLocationSearch, MediaFileType, NumberingSearch } from '../../../core/types/api';
import ItemLink, { InterviewLink } from '../../ItemLink';
import { middlegrey } from '../../../ui/Core/stylesheets/colors';

function ListLocation({ channelName, location }: EvidenceLocationSearch) {
	return (
		<p>
			{channelName && (
				<>
					<small>{channelName}:</small>
					<br />
				</>
			)}
			{location || <span>[empty location / whole file]</span>}
		</p>
	);
}

const infoCellClass = css`
	padding: 8px 16px;
`;

const cellClass = css`
	border-bottom: 1px solid ${middlegrey};
`;

function ListEntry({
	projectId,
	itemId,
	chapterId,
	numbering,
	subItemName,
	name,
	evidenceLocations,
	usageType,
}: NumberingSearch & { projectId?: string }) {
	return (
		<tr>
			<td className={cellClass}>
				{usageType === MediaFileType.InterviewAttachment ? (
					<InterviewLink projectId={projectId} id={itemId} />
				) : (
					<ItemLink
						projectId={projectId}
						rocItemId={itemId}
						chapterId={chapterId}
						chapter={numbering}
					/>
				)}
			</td>
			<td className={cx(cellClass, infoCellClass)} width={500}>
				{name && <Box fontWeight="bold">{name}</Box>}
				{subItemName && <p>{subItemName}</p>}
			</td>
			<td className={cellClass} width={500}>
				{evidenceLocations?.map((l, idx) => <ListLocation {...l} key={idx} />)}
			</td>
		</tr>
	);
}

function ListUsagesData({ list, projectId }) {
	if (!list) return null;

	if (list.length < 1) return <div>None</div>;

	return (
		<table>
			<tbody>
				{list.map(({ itemId, ...rest }) => (
					<ListEntry itemId={itemId} key={itemId} projectId={projectId} {...rest} />
				))}
			</tbody>
		</table>
	);
}

function ListUsagesPop({ id, projectId, close, fileInfo }) {
	const { state } = useAPI({
		props: {
			query: `Attachments/ListUsages/${projectId}/${id}`,
		},
		autoLoad: true,
	});

	const list = state?.autodata?.data;

	return (
		<MaterialFlyOut open={!!id} onClose={close} title="Usage">
			<Box p="4px" position="sticky" top={0} bgcolor="white" zIndex={1}>
				{fileInfo}
			</Box>
			{list && <ListUsagesData list={list} projectId={projectId} />}
		</MaterialFlyOut>
	);
}

function ListUsagesButton({ id, projectId, fileInfo }) {
	const { isOn, toggleOn, toggleOff } = useToggle();

	return (
		<>
			<SimplifiedButton
				size="small"
				variant="outlined"
				startIcon={<FormatListNumbered />}
				onClick={toggleOn}
			>
				Usage
			</SimplifiedButton>
			{isOn && (
				<ListUsagesPop close={toggleOff} id={id} projectId={projectId} fileInfo={fileInfo} />
			)}
		</>
	);
}

export default function ListUsagesWrapper({ id, projectId, fileInfo }) {
	const { 'Attachments/ListUsages': canList } = useAllowedMethods();

	if (!canList || !id || !projectId) return null;

	return <ListUsagesButton id={id} projectId={projectId} fileInfo={fileInfo} />;
}
