import React from 'react';

export function useCheckboxHandle(setState: React.Dispatch<React.SetStateAction<boolean>>) {
	return React.useCallback((e) => setState(e.target.checked), [setState]);
}

export function useToggle(initial = false) {
	const [isOn, setOn] = React.useState(initial);

	const handleCheckbox = useCheckboxHandle(setOn);

	const toggleOn = React.useCallback(() => {
		setOn(true);
	}, [setOn]);

	const toggleOff = React.useCallback(() => {
		setOn(false);
	}, [setOn]);

	const toggle = React.useCallback(() => {
		setOn((prevValue) => !prevValue);
	}, [setOn]);

	return { isOn, setOn, toggle, toggleOn, toggleOff, handleCheckbox };
}
