export enum UploadStates {
	Waiting,
	Uploading,
	Complete,
	Error,
}

export enum FileExtMimes {
	Jpg = 'image/jpeg',
	Png = 'image/png',
	Pdf = 'application/pdf',
	Doc = 'application/msword',
	Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	Xls = 'application/vnd.ms-excel',
	Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	Txt = 'text/plain',
	Tiff = 'image/tiff',
	Ics = 'text/calendar',
	Svg = 'image/svg+xml',
	Json = 'application/json',
	Ico = 'image/x-icon',
}

export type AcceptFileType = (FileExtMimes | string)[];
