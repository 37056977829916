import React from 'react';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { Button } from '../../core/components';
import { useReportAccess } from '../../auth/hooks';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { ItemStatus } from '../../project/enums';
import { useAPI, useToggle } from '../../core/hooks';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import MaterialDialog from '../../flyout/components/MaterialDialog';
import { PureTextField } from '../../formaggio';
import { useFieldHandler } from '../../core/helpers/stateActionHelpers';
import { useAuthContext } from '../../auth/context';
import { WorkflowRole } from '../../core/types/api';
import { useItemStatus } from '../../rocTemplates/hooks';
import SimplifiedButton from '../../rocTemplates/simplifiedControls/SimplifiedButton';

function getApprovedDecision(workflowRole: WorkflowRole, isQAEnabled: boolean): ItemStatus {
	if (workflowRole === WorkflowRole.Qsa) return ItemStatus.ReadyForPeerReview;
	if (workflowRole === WorkflowRole.QA) return ItemStatus.Approved;

	//If peer reviewer
	return isQAEnabled ? ItemStatus.ReadyForQA : ItemStatus.Approved;
}

export default function WorkflowQuickApprove() {
	const { globalOptions = {} } = useAuthContext();
	const { workflowRole } = useReportAccess();
	const { projectId } = useReportContext();
	const { itemId } = useItemContext();
	const status = useItemStatus();
	const { initialFetch } = useAPI({
		props: { query: 'Workflow/Create', method: 'POST', useFormData: true },
	});
	const roleDecision = React.useMemo(() => {
		const by = {
			qsa: workflowRole === WorkflowRole.Qsa && status === ItemStatus.WaitingForQsa,
			peerReviewer:
				workflowRole === WorkflowRole.PeerReviewer &&
				(status === ItemStatus.ReadyForPeerReview ||
					(!globalOptions.workflowQAEnabled && status === ItemStatus.ReadyForQA)),
			qa: workflowRole === WorkflowRole.QA && status === ItemStatus.ReadyForQA,
		};

		let approveLabel = '';
		let declineLabel = '';

		if (by.qsa) approveLabel = 'Move to peer review';
		if (by.peerReviewer || by.qa) declineLabel = 'Request changes';
		if (by.peerReviewer) approveLabel = globalOptions.workflowQAEnabled ? 'Move to QA' : 'Approve';
		if (by.qa) approveLabel = 'Approve';

		return {
			by,
			approveLabel,
			declineLabel,
			approveStatus: getApprovedDecision(workflowRole, globalOptions.workflowQAEnabled),
		};
	}, [globalOptions.workflowQAEnabled, status, workflowRole]);

	const decide = React.useCallback(
		(decision: boolean, message?: string) => {
			initialFetch({
				params: {
					projectId,
					itemId,
					status: decision ? roleDecision.approveStatus : ItemStatus.ChangesRequested,
					message,
					visibleToCustomers: false,
				},
			});
		},
		[initialFetch, projectId, itemId, roleDecision.approveStatus],
	);

	const { isOn, toggleOn, toggleOff } = useToggle();
	const [reason, setReason] = React.useState('');
	const handleReason = useFieldHandler(setReason);
	const decline = React.useCallback(() => {
		decide(false, reason);
		toggleOff();
	}, [decide, reason, toggleOff]);

	const handleApprove = React.useCallback(() => decide(true), [decide]);

	if (roleDecision.by.qsa || roleDecision.by.peerReviewer || roleDecision.by.qa)
		return (
			<>
				{roleDecision.approveLabel && (
					<SimplifiedButton startIcon={<ThumbUp />} onClick={handleApprove}>
						{roleDecision.approveLabel}
					</SimplifiedButton>
				)}
				{roleDecision.declineLabel && (
					<SimplifiedButton startIcon={<ThumbDown />} onClick={toggleOn}>
						{roleDecision.declineLabel}
					</SimplifiedButton>
				)}
				<MaterialDialog
					isOpen={isOn}
					actions={
						<>
							<Button color="primary" onClick={decline}>
								Submit
							</Button>
							<Button color="secondary" onClick={toggleOff}>
								Cancel
							</Button>
						</>
					}
					onClose={toggleOff}
				>
					Enter reason on why this item was declined
					<PureTextField
						fullWidth
						multiline
						variant="outlined"
						value={reason}
						onChange={handleReason}
					/>
				</MaterialDialog>
			</>
		);

	return null;
}
