import React, { ChangeEvent } from 'react';
import { getValueByInputType } from './index';

export function useFieldHandler(setState: React.Dispatch<React.SetStateAction<any>>) {
	return React.useCallback(
		(e: ChangeEvent<HTMLInputElement>) => setState(getValueByInputType(e)),
		[setState],
	);
}

export function useMultiFieldHandler<T extends Record<string, unknown>>(
	setState: React.Dispatch<React.SetStateAction<T>>,
) {
	return React.useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			setState((prevState) => ({
				...prevState,
				[e.target.name]: getValueByInputType(e),
			})),
		[setState],
	);
}
