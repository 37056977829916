import { css, cx } from '@emotion/css/macro';
import { Link, LinkProps } from '@mui/material';
import { darkness } from '../stylesheets/constants';

const rootClass = css`
	text-decoration: none;
`;

const forButtonClass = css`
	text-decoration: none !important;
	color: ${darkness} !important;
`;

type SimpleLinkProps = LinkProps & {
	isForButton?: boolean;
};

/**
 * Use this only with external links. For internal navigation use RouterLink.
 */
export default function SimpleLink({
	href,
	className,
	children,
	isForButton,
	...rest
}: SimpleLinkProps) {
	return (
		<Link
			href={href}
			rel="noopener noreferrer"
			className={cx(rootClass, isForButton ? forButtonClass : null, className)}
			underline="always"
			{...rest}
		>
			{children}
		</Link>
	);
}
