import { css, cx } from '@emotion/css/macro';
import { MentionPluginConfig } from '@draft-js-plugins/mention';
import { darkgrey, darkness } from '../../stylesheets/constants';

const mentionSuggestionEntry = css`
	background-color: white;
	display: flex;
	align-items: center;
	flex-direction: row;
	padding: 8px 16px;
	border-radius: 5px;
`;

export const mentionDraftPluginConfig: MentionPluginConfig = {
	theme: {
		mention: css`
			background: ${darkness};
			color: white;
			text-decoration: none;
			padding: 2px 4px;
			border-radius: 5px;
		`,
		mentionSuggestions: css`
			border: 1px solid ${darkgrey};
			background: white;
			border-radius: 5px;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			z-index: 100000;
			box-shadow: 0px 1px 8px -3px ${darkgrey};
		`,
		mentionSuggestionsEntry: mentionSuggestionEntry,
		mentionSuggestionsEntryAvatar: css`
			display: block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-right: 8px;
		`,
		mentionSuggestionsEntryFocused: cx(
			mentionSuggestionEntry,
			css`
				background: ${darkness};
				color: white;
			`,
		),
		mentionSuggestionsEntryText: css`
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`,
	},
	entityMutability: 'IMMUTABLE',
	mentionPrefix: '@',
};
