import { TextField, Tooltip } from '@material-ui/core';
import { css } from '@emotion/css/macro';
import React from 'react';
import { Delete, Edit, LockOpen, Save } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ChipPropsColorOverrides } from '@mui/material/Chip/Chip';
import { Channel } from '../../../core/types/api';
import { Button } from '../../../core/components';
import MaterialDialog from '../../../flyout/components/MaterialDialog';
import { useToggle } from '../../../core/hooks';
import { useReportAccess } from '../../../auth/hooks';
import { useItemContext } from '../../../rocTemplates/context/ItemContext';
import SimplifiedButton from '../../../rocTemplates/simplifiedControls/SimplifiedButton';
import { CustomSaqStatus } from '../../../customSaq/enums';
import {
	DEFAULT_RFT_NA_MESSAGE,
	DEFAULT_RFT_NOT_TESTED_MESSAGE,
} from '../../../customSaq/constants';

const channelClass = css`
	display: flex;
	align-items: center;
	margin: 0.25rem 0.5rem 0.25rem 0;
`;

type NotApplicableReasonProps = {
	status: CustomSaqStatus;
	pc: Channel;
	overrideSaq: any;
	useDifferentValuesPerPaymentChannels?: boolean;
	canEdit: boolean;
};

type SaqOverrideProps = {
	channelName: string;
	channelId: string;
	overrideSaq: any;
	useDifferentValuesPerPaymentChannels?: boolean;
	canEdit: boolean;
	IsSummary?: boolean;
};

type channelDetailsProps = {
	message: string;
	color: OverridableStringUnion<'default' | 'warning' | 'error', ChipPropsColorOverrides>;
};

function ChannelReason({
	status,
	pc,
	overrideSaq,
	useDifferentValuesPerPaymentChannels,
	canEdit,
}: NotApplicableReasonProps) {
	const { message, color }: channelDetailsProps = React.useMemo(() => {
		switch (status) {
			case CustomSaqStatus.NotApplicable:
				return {
					message: pc?.saq?.notApplicableReason || DEFAULT_RFT_NA_MESSAGE,
					color: 'default',
				};
			case CustomSaqStatus.NotTested:
				return {
					message: pc?.saq?.notTestedReason || DEFAULT_RFT_NOT_TESTED_MESSAGE,
					color: 'warning',
				};
			default:
				return { message: 'Unknown', color: 'error' };
		}
	}, [pc, status]);

	const [naReason, setNaReason] = React.useState(message);
	React.useEffect(() => setNaReason(message), [message]);

	const onReasonChange = React.useCallback((e) => setNaReason(e.target.value), [setNaReason]);
	const { isOn, toggleOn, toggleOff } = useToggle();
	const confirmNewNa = React.useCallback(() => {
		overrideSaq(pc?.id, true, naReason);
		toggleOff();
	}, [toggleOff, pc, overrideSaq, naReason]);

	const confirmOverride = React.useCallback(() => {
		overrideSaq(pc?.id, true);
		toggleOff();
	}, [toggleOff, pc, overrideSaq]);
	const label = pc?.name;

	if (message === 'Unknown') return null;

	return (
		<>
			<Box className={channelClass}>
				<Tooltip title="RFT N/A Reason">
					<Chip label={label} color={color} />
				</Tooltip>
				<Box margin="0 0.5rem">{message}</Box>
				{useDifferentValuesPerPaymentChannels && canEdit && (
					<SimplifiedButton startIcon={<Edit />} onClick={toggleOn} size="small" tabIndex={-1}>
						Override
					</SimplifiedButton>
				)}
			</Box>
			<MaterialDialog
				title={`Override the response for ${label}?`}
				isOpen={isOn}
				actions={
					<>
						<Button onClick={confirmNewNa} startIcon={<Save />}>
							Save new N/A reason
						</Button>
						<Button onClick={confirmOverride} startIcon={<LockOpen />}>
							Mark Applicable
						</Button>
						<Button color="secondary" onClick={toggleOff}>
							Cancel
						</Button>
					</>
				}
				onClose={toggleOff}
			>
				<p>
					You can edit &apos;Current N/A response&apos; and Save it OR <b>Mark</b> the requirement{' '}
					<b>Applicable</b> for this channel
				</p>
				<TextField
					label="Current N/A Reason"
					name="naReason"
					value={naReason}
					onChange={onReasonChange}
					fullWidth
				/>
			</MaterialDialog>
		</>
	);
}

function ChannelReasonOverride({
	channelName,
	channelId,
	overrideSaq,
	useDifferentValuesPerPaymentChannels,
	IsSummary,
	canEdit,
}: SaqOverrideProps) {
	const overrideRemove = React.useCallback(
		() => overrideSaq(channelId, false),
		[channelId, overrideSaq],
	);
	const { isOn, toggleOn, toggleOff } = useToggle();
	const confirm = React.useCallback(() => {
		overrideRemove();
		toggleOff();
	}, [toggleOff, overrideRemove]);

	return (
		<>
			<Box className={channelClass}>
				<Tooltip title="Default RFT response has been overridden and will be omitted in the ROC printout">
					<Chip label={channelName} color="info" />
				</Tooltip>
				<Box margin="0 0.5rem">RFT Override</Box>
				{(useDifferentValuesPerPaymentChannels || IsSummary) && canEdit && (
					<SimplifiedButton startIcon={<Delete />} onClick={toggleOn} size="small" tabIndex={-1}>
						Remove override
					</SimplifiedButton>
				)}
			</Box>
			<MaterialDialog
				title={`Do you want to remove override for ${channelName} and use the default response? `}
				isOpen={isOn}
				actions={
					<>
						<Button color="primary" onClick={confirm}>
							Yes
						</Button>
						<Button color="secondary" onClick={toggleOff}>
							Cancel
						</Button>
					</>
				}
				onClose={toggleOff}
			>
				Any response that has been provided as an override needs to be removed manually
			</MaterialDialog>
		</>
	);
}

function SummarySaqStatusHint({ states = [] }: any) {
	if (
		states.length === 0 ||
		states.filter((s) => s.status === CustomSaqStatus.Applicable).length > 0
	)
		return null;

	if (states.filter((s) => s.status === CustomSaqStatus.NotTested).length > 0)
		return <h3>Not tested</h3>;

	return <h3>Not applicable</h3>;
}

function ChannelReasonList() {
	const { paymentChannels = [], isAQSA } = useReportAccess();
	const {
		lists,
		overrideSaqPC,
		useDifferentValuesPerPaymentChannels,
		itemProperties,
		isItemApproved,
	} = useItemContext();
	const canEdit = React.useMemo(() => isAQSA && !isItemApproved, [isAQSA, isItemApproved]);
	const isAllChannelOverride = React.useMemo(
		() => lists.saqOverridePcIds.filter((c) => c.cid == null).length > 0,
		[lists.saqOverridePcIds],
	);

	const reasons = React.useMemo(() => {
		const applicableChannelIds = lists.allChannelStates
			.filter((c) => c.status === CustomSaqStatus.Applicable)
			.map((s) => s.cid);

		return paymentChannels
			.filter(
				({ id = '' }) =>
					applicableChannelIds.indexOf(id) < 0 &&
					lists.saqOverridePcIds.filter((c) => c.cid === id).length === 0 &&
					!isAllChannelOverride,
			)
			.map((pc, idx) => (
				<ChannelReason
					status={
						lists.allChannelStates.filter(
							(c) => c.status !== CustomSaqStatus.Applicable && c.cid === pc.id,
						)[0]?.status
					}
					pc={pc}
					overrideSaq={overrideSaqPC}
					key={idx}
					useDifferentValuesPerPaymentChannels={useDifferentValuesPerPaymentChannels}
					canEdit={canEdit}
				/>
			));
	}, [
		lists.allChannelStates,
		lists.saqOverridePcIds,
		isAllChannelOverride,
		paymentChannels,
		overrideSaqPC,
		useDifferentValuesPerPaymentChannels,
		canEdit,
	]);

	const channelWithOverrides = React.useMemo(
		() =>
			lists.saqOverridePcIds.map((cs, idx) => {
				const channel = paymentChannels.filter((pc) => pc.id === cs.cid)[0];
				return (
					<ChannelReasonOverride
						channelName={channel?.name || 'All Channels'}
						channelId={channel?.id || null}
						overrideSaq={overrideSaqPC}
						key={idx}
						useDifferentValuesPerPaymentChannels={useDifferentValuesPerPaymentChannels}
						IsSummary={itemProperties.isSummary}
						canEdit={canEdit}
					/>
				);
			}),
		[
			paymentChannels,
			overrideSaqPC,
			useDifferentValuesPerPaymentChannels,
			itemProperties.isSummary,
			lists.saqOverridePcIds,
			canEdit,
		],
	);

	const overrideSummary = React.useCallback(() => overrideSaqPC(null, true), [overrideSaqPC]);

	if (reasons.length === 0 && channelWithOverrides.length === 0) return null;

	return (
		<>
			{itemProperties.isSummary && !isAllChannelOverride && (
				<Box>
					<SummarySaqStatusHint states={lists.allChannelStates} />
					{canEdit && (
						<SimplifiedButton startIcon={<Edit />} onClick={overrideSummary}>
							Override
						</SimplifiedButton>
					)}
				</Box>
			)}
			<Box padding="4px 0">{reasons}</Box>
			<Box padding="4px 0">{channelWithOverrides}</Box>
		</>
	);
}

export default React.memo(ChannelReasonList);
