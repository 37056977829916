import { DownloadState } from '../../shared/modules/files/constants';
import { AUTH_TOKEN_KEY } from '../auth/constants';
import { downloadRestrictedFile } from '../../shared/modules/files/helpers';
import { AcceptFileType, FileExtMimes } from './constants';

export function sequence(tasks: (() => Promise<any>)[]) {
	return tasks.reduce((promise, task) => promise.then(task), Promise.resolve());
}

type DownloadRequestProps = {
	query: string;
	setDownloadState: (state: DownloadState) => void;
	fileName?: string;
	qs?: any;
};

export function downloadFile(props: DownloadRequestProps) {
	return downloadRestrictedFile({
		authTokenKey: AUTH_TOKEN_KEY,
		...props,
	});
}

export function isValidMimeType(type: string, accept?: string) {
	if (!accept) return true;

	return (
		accept
			.replace('*', '')
			.split(',')
			.filter((acceptValue) => type.indexOf(acceptValue) >= 0).length > 0
	);
}

export function extractMimeTypeFromFileName(fileName?: string): FileExtMimes | string {
	if (!fileName) return '';

	// Just a basic logic since we added ContentType field to MediaFile and check by that in the first place
	// This here is for backward compatibility
	if (fileName.endsWith('.png')) return FileExtMimes.Png;
	if (fileName.endsWith('.jpg')) return FileExtMimes.Jpg;
	if (fileName.endsWith('.jpeg')) return FileExtMimes.Jpg;
	if (fileName.endsWith('.tiff')) return FileExtMimes.Tiff;

	return '';
}

export function acceptsToString(accept: AcceptFileType = []) {
	return accept.join(',');
}
