import { Edit, FileUpload } from '@mui/icons-material';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import EvidenceCreate from './EvidenceCreate';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import { useHistoryPush, useToggle } from '../../core/hooks';
import { useAllowedMethods } from '../../auth/hooks';
import SimplifiedButtonGroup from '../../rocTemplates/simplifiedControls/SimplifiedButtonGroup';
import SimplifiedButton from '../../rocTemplates/simplifiedControls/SimplifiedButton';

export default function ProjectEvidenceUploader({
	isEvidenceScreen,
	modelId,
	refreshList,
}: {
	isEvidenceScreen?: boolean;
	modelId?: string;
	refreshList?: any;
}) {
	const { projectId, refresh } = useReportContext();
	const { isOn, toggleOff, toggleOn } = useToggle();
	const goToEvidences = useHistoryPush(`/portal/evidence/${projectId}`);
	const { 'Evidences/UpdateAttachment': canEdit, 'Evidences/Create': canCreate } =
		useAllowedMethods();

	if (!canCreate && !canEdit) return null;

	return (
		<>
			<SimplifiedButtonGroup style={{ marginBottom: '5px' }}>
				{canCreate && (
					<SimplifiedButton startIcon={<FileUpload />} onClick={toggleOn}>
						Attach evidence
					</SimplifiedButton>
				)}
				{canEdit && !isEvidenceScreen && (
					<SimplifiedButton startIcon={<Edit />} onClick={goToEvidences}>
						Manage evidence
					</SimplifiedButton>
				)}
			</SimplifiedButtonGroup>
			<MaterialFlyOut open={isOn} onClose={toggleOff} title="Attachment">
				<EvidenceCreate
					onCompleted={() => {
						toggleOff();
						if (refreshList) refreshList();
						if (refresh) refresh();
					}}
					modelId={projectId || modelId}
				/>
			</MaterialFlyOut>
		</>
	);
}
