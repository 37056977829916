import { useQuery } from '@apollo/client';
import React from 'react';
import { GET_MY_PROJECTS_QUERY } from '../../graphql/domains/project/queryList';
import { idsToProjectPath } from './helpers';
import { useAPI, useHistory, useHistoryQS } from '../core/hooks';
import { usePermalinkHighlightQS } from '../rocTemplates/hooks';
import useSuccessPopup from '../popup/hooks/useSuccessPopup';
import { ReportFilterType } from '../rocTemplates/filtering/reportFilterStorage';
import { ProjectInfoAccess } from '../core/types/api';

export function useMyProjects(skip = false, sortByName = false, filter = {}) {
	const { data, refetch } = useQuery(GET_MY_PROJECTS_QUERY, {
		skip,
		fetchPolicy: 'network-only',
		variables: filter,
	});

	const projectData = data?.list?.data;

	const projects =
		projectData && sortByName
			? [...projectData].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
			: projectData;

	return [projects, refetch];
}

export function useProjectInfo(projectId?: string): {
	data: ProjectInfoAccess;
	refetch: () => void;
} {
	const { fetchAPI } = useAPI({});
	const [view, setView] = React.useState<ProjectInfoAccess>(undefined);
	const load = React.useCallback(
		() =>
			fetchAPI({
				query: `Project/ViewInfo/${projectId}`,
				onSuccess: (result: any) => {
					setView(result?.data);
				},
			}),
		[fetchAPI, projectId],
	);

	React.useEffect(() => {
		if (projectId) load();
	}, [projectId, load]);

	return { data: view || {}, refetch: load };
}

export function useNextRequest(fetchAPI: any, projectId: string, currentItemId?: string) {
	const history = useHistory();

	return React.useCallback(
		() =>
			fetchAPI({
				query: 'RocItemControllerNew/ViewReportNextRequest',
				method: 'GET',
				qs: { projectId, currentItemId },
				onSuccess: ({ data }: any) => {
					const { requestChapterId, requestItemId } = data || {};
					if (requestChapterId && requestItemId)
						history.push(idsToProjectPath(projectId, requestChapterId, requestItemId));
					// eslint-disable-next-line no-alert
					else alert('No pending requests');
				},
			}),
		[history, fetchAPI, projectId, currentItemId],
	);
}

export function useStatusQS() {
	return useHistoryQS('itemStatus') as string;
}

export function useWarningQS() {
	return useHistoryQS('rocWarning') as string;
}

export function useAssessmentStatusQS() {
	return useHistoryQS('assessmentStatus') as string;
}

export function useNextItem(
	projectId: string,
	filter: ReportFilterType,
	itemId?: string,
	boundaryId?: string,
	nextItemStatus?: string,
	nextItemWarning?: string,
	nextAssessmentStatus?: string,
) {
	const { fetchAPI } = useAPI();
	const showSuccessPopUp = useSuccessPopup();
	const history = useHistory();
	const itemIdQS = usePermalinkHighlightQS();

	return React.useCallback(() => {
		const presetFilter = {
			...(nextItemStatus && { itemStatus: nextItemStatus }),
			...(nextItemWarning && { rocWarning: nextItemWarning }),
			...(nextAssessmentStatus && { assessmentStatus: nextAssessmentStatus }),
		};
		const hasPreset = !!nextItemStatus || !!nextItemWarning || !!nextAssessmentStatus;

		fetchAPI({
			query: 'RocItemControllerNew/ViewReportNextItem',
			method: 'POST',
			qs: {
				projectId,
				currentChapterId: itemId,
				currentItemId: itemIdQS,
				boundaryChapterId: boundaryId,
			},
			params: {
				...filter,
				...presetFilter,
				...(hasPreset ? { saqEnabled: false } : {}),
			},
			onSuccess: ({ data, optionalMessage }: any) => {
				if (optionalMessage) showSuccessPopUp(optionalMessage);
				if (data) {
					const url = idsToProjectPath(projectId, data.chapterId, {
						id: data.itemId,
						boundaryId: boundaryId || undefined,
						...presetFilter,
					});

					history.push(url);
				}
			},
		});
	}, [
		nextItemStatus,
		nextItemWarning,
		nextAssessmentStatus,
		fetchAPI,
		projectId,
		itemId,
		itemIdQS,
		boundaryId,
		filter,
		showSuccessPopUp,
		history,
	]);
}
