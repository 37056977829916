import React from 'react';
import Box from '@mui/material/Box';
import { css } from '@emotion/css/macro';
import { BasicForm, TextField } from '../../formaggio';
import { ValidationErrors } from '../../formaggio/types';
import { Button } from '../../core/components';
import { allRolesFlags } from '../../auth/constants';
import CompanyClientRoleSelector from './CompanyClientRoleSelector';
import { isPasswordSafe } from '../../core/components/PasswordStrength';
import ClientUserFormFields from '../../client/ClientUserFormFields';
//import { useMyProjects } from '../../project/hooks';
//import { projectsToSelectOptions } from '../../project/helpers';

const rootClass = css`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

type RegistrationFormProps = { password: string; confPassword: string; role?: number };

type RegistrationProps = {
	onSuccess: (response: object) => void;
	formQuery: string;
	formInitial?: object;
	isAdmin?: boolean;
	showEmailField?: boolean;
	showPasswordField?: boolean;
	showUserNameField?: boolean;
	isClient?: boolean;
};

export default function UserRegistration({
	onSuccess,
	formQuery,
	formInitial = {},
	isAdmin,
	showEmailField,
	showPasswordField,
	showUserNameField,
	isClient,
}: RegistrationProps) {
	const validate = React.useCallback(
		(values: RegistrationFormProps) => {
			const errors: ValidationErrors<RegistrationFormProps> = {};
			if (showPasswordField) {
				if (!values.password) errors.password = true;
				if (!values.confPassword) errors.confPassword = true;
				if (!isPasswordSafe(values.password)) errors.password = 'Password is not safe';
				if (values.password !== values.confPassword) errors.confPassword = "Passwords don't match";
			}
			if (!values.role && isAdmin) errors.role = 'Role required';
			return errors;
		},
		[isAdmin, showPasswordField],
	);

	return (
		<BasicForm
			fetchConfig={{
				onSuccess,
				query: formQuery,
				method: 'POST',
				successMessage: true,
			}}
			validate={validate}
			initialValues={formInitial}
			className={rootClass}
		>
			{showUserNameField && (
				<TextField label="Username" name="userName" required fullWidth maxLength="256" />
			)}
			{showPasswordField && (
				<>
					<TextField
						label="Password"
						name="password"
						type="password"
						required
						fullWidth
						isNewPasswordField
						InputProps={{ autoComplete: 'new-password' }}
					/>
					<TextField
						label="Confirm password"
						name="confPassword"
						type="password"
						fullWidth
						required
						InputProps={{ autoComplete: 'new-password' }}
					/>
				</>
			)}
			{(isAdmin || showEmailField) && (
				<TextField label="E-mail" name="email" type="email" required fullWidth maxLength="100" />
			)}
			<TextField label="First Name" name="firstName" required fullWidth />
			<TextField label="Last Name" name="lastName" required fullWidth />
			<TextField label="Phone" type="tel" name="phone" fullWidth />
			<TextField label="Title / Custom credentials" name="customCredentials" fullWidth />
			<TextField label="Organization" name="organization" fullWidth helperText="e.g. IT, HR etc" />
			{isClient && <ClientUserFormFields />}
			{isAdmin && <CompanyClientRoleSelector selectableRoles={allRolesFlags} />}
			<Box textAlign="center" mt={3}>
				<Button color="primary" type="submit">
					Submit
				</Button>
			</Box>
		</BasicForm>
	);
}
