import React from 'react';
import { ReasonBase } from '../core/types/api';
import ItemReasons from './ItemReasons';
import { useAllowedMethods } from '../auth/hooks';
import { useItemContext } from './context/ItemContext';

type NotApplicableControlProps = {
	itemId?: string;
	notApplicable?: boolean;
	notApplicableReasons?: ReasonBase[];
};

export default function useNotApplicableControl({
	itemId,
	notApplicable,
	notApplicableReasons,
}: NotApplicableControlProps) {
	const { 'RocItemControllerNew/SetApplicable': canSetApplicable } = useAllowedMethods();
	const { updateItemContextReasons, isSaqNaOverride, lists } = useItemContext();

	//Not applicable handling
	const [applicableReasonsState, setApplicableReasons] = React.useState<ReasonBase[] | undefined>(
		notApplicableReasons,
	);
	const [applicableClicked, setApplicableClicked] = React.useState(false);
	React.useEffect(() => {
		setApplicableReasons(notApplicable ? notApplicableReasons : undefined);
	}, [notApplicable, notApplicableReasons]);
	React.useEffect(() => {
		if (isSaqNaOverride) setApplicableReasons(lists.notApplicableReasons);
	}, [isSaqNaOverride, lists.notApplicableReasons]);
	const clickNotApplicable = React.useCallback(() => {
		setApplicableReasons([{}]);
		setApplicableClicked(true);
	}, [setApplicableReasons]);

	const setReasons = React.useCallback(
		(v) => {
			updateItemContextReasons(v);
			setApplicableReasons(v);
		},
		[updateItemContextReasons, setApplicableReasons],
	);

	return {
		notApplicableReasonsComponent: (!!applicableReasonsState || isSaqNaOverride) && (
			<ItemReasons
				methodName="SetApplicable"
				reasons={applicableReasonsState}
				setReasons={setReasons}
				canEdit={canSetApplicable}
				applicableClicked={applicableClicked}
				setApplicableClicked={setApplicableClicked}
				itemId={itemId}
			/>
		),
		clickNotApplicable,
	};
}
