import React from 'react';
import { css, cx } from '@emotion/css/macro';
import Tooltip from '@material-ui/core/Tooltip';
import { Cancel, Edit, Save, StickyNote2, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Workflow } from '../../core/types/api';
import { darkgrey, lightgrey, mainDark, warning } from '../../ui/Core/stylesheets/colors';
import { convertToHumanTime } from '../../core/helpers';
import { Heading } from '../../core/components';
import WorkflowRequestEntry from './WorkflowRequestEntry';
import ResourceAction from '../../auth/components/ResourceAction';
import WorkflowAuthor from './WorkflowAuthor';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { progressStatuses } from '../../project/progressCalculation';
import WorkflowAttachment from './WorkflowAttachment';
import { useAuthContext } from '../../auth/context';
import { useToggle } from '../../core/hooks';
import { BasicForm, CheckboxField } from '../../formaggio';
import { useReportAccess } from '../../auth/hooks';
import WorkflowLinkedData from './linkedData/WorkflowLinkedData';
import {
	convertDraftToText,
	isDraftMessageVisible,
	setInitialDraftEditorState,
} from '../../../shared/modules/draft/helpers';
import { FormDraftField } from '../../../shared/modules/draft/FormDraftField';
import PureDraftEditor from '../../../shared/modules/draft/PureDraftEditor';

const rootClass = css`
	padding: 8px;
	border-bottom: 1px solid ${darkgrey};
`;

const userInfoClass = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 600;
`;

const entryInfoClass = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4px;
`;

const resourceAccessClass = css`
	margin-left: 8px;
`;

const messageClass = css`
	padding: 8px;
	border-left: 1px solid ${darkgrey};
`;

const workPaperClass = css`
	border-radius: 8px;
	border: 2px solid ${warning};
	margin: 4px 0;
`;

const worknoteIconClass = css`
	margin-right: 8px;
`;

type WorkflowEntryProps = Workflow & {
	availableChannelOptions: any[];
	refresh: () => void;
	readOnly?: boolean;
};

export default function WorkflowEntry({
	id,
	resource,
	access,
	createdDate,
	modifiedDate,
	visibleToRoles,
	systemMessage,
	message = '',
	status,
	createdBy,
	request,
	attachments,
	availableChannelOptions,
	isWorkPaper,
	projectId,
	itemId,
	refresh,
	readOnly,
	linkedDataId,
	linkedDataType,
}: WorkflowEntryProps) {
	const { users, contacts } = useReportContext();
	const { isAQSA } = useReportAccess();
	const { id: myId } = useAuthContext();
	const { isOn, toggleOn, toggleOff } = useToggle();

	const onSaveSuccess = React.useCallback(() => {
		toggleOff();
		refresh();
	}, [refresh, toggleOff]);

	if (!id) return null;

	return (
		<div className={cx(rootClass, isWorkPaper && workPaperClass)}>
			<div className={userInfoClass}>
				<Box display="flex" alignItems="center">
					{isWorkPaper && (
						<Tooltip title="Worknote">
							<StickyNote2 className={worknoteIconClass} htmlColor={warning} />
						</Tooltip>
					)}
					<WorkflowAuthor {...createdBy} users={users} contacts={contacts} />
				</Box>
				{!!myId && myId === createdBy?.authorId && !readOnly && (
					<IconButton onClick={toggleOn}>
						<Edit />
					</IconButton>
				)}
				{visibleToRoles && (
					<Tooltip title="This entry is not visible to customers">
						<VisibilityOff htmlColor={mainDark} className={resourceAccessClass} />
					</Tooltip>
				)}
			</div>
			<div className={entryInfoClass}>
				<Heading variant="caption" color="textSecondary">
					{convertToHumanTime(createdDate)}
					{createdDate !== modifiedDate && <> (edited {convertToHumanTime(modifiedDate)})</>}
				</Heading>
				<Heading variant="caption" color="textPrimary" className={resourceAccessClass}>
					<ResourceAction resource={resource} action={access} />
				</Heading>
			</div>
			{request && <WorkflowRequestEntry users={users} contacts={contacts} {...request} />}
			{systemMessage && (
				<Box fontSize="12px" p="4px" bgcolor={lightgrey} borderRadius="5px" mb="4px">
					System: {systemMessage}
				</Box>
			)}
			{(isDraftMessageVisible(message) || isOn) && (
				<div className={messageClass}>
					{isOn ? (
						<BasicForm
							fetchConfig={{
								query: `Workflow/Update/${id}`,
								method: 'PUT',
								onSuccess: onSaveSuccess,
							}}
							initialValues={{ message, projectId, itemId, isWorkPaper }}
						>
							<FormDraftField name="message" editorKey={id + itemId} />
							{isAQSA && !isWorkPaper && <CheckboxField name="isWorkPaper" label="Worknote" />}
							<IconButton type="submit">
								<Save />
							</IconButton>
							<IconButton onClick={toggleOff}>
								<Cancel />
							</IconButton>
						</BasicForm>
					) : (
						<PureDraftEditor
							editorState={setInitialDraftEditorState(message)}
							onChange={() => undefined}
							readOnly
							editorKey={id + itemId}
						/>
					)}
				</div>
			)}
			{attachments && attachments.length > 0 && (
				<WorkflowAttachment
					workflowId={id}
					message={convertDraftToText(message)}
					availableChannelOptions={availableChannelOptions}
					attachmentLinks={attachments}
					refreshList={refresh}
				/>
			)}
			{linkedDataId && <WorkflowLinkedData type={linkedDataType} id={linkedDataId} />}
			{status && (
				<div
					className={css`
						color: ${progressStatuses[status].color};
					`}
				>
					&rarr; <span>{progressStatuses[status].title}</span>
				</div>
			)}
		</div>
	);
}
