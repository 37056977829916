import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { useFormikContext } from 'formik';
import { Upload } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useDragDropFile } from '../../files/hooks';
import { draggedOverClass, HiddenFileInput } from '../../files/DragDropComponents';
import PreUploadFile from '../../files/PreUploadFile';
import { mainLight } from '../../ui/Core/stylesheets/colors';
import { AcceptFileType } from '../../files/constants';
import { acceptsToString } from '../../files/helpers';

const dragDropRootClass = css`
	display: flex;
	position: relative;
	flex-direction: column;
	background: white;
	border-radius: 4px;
`;

const uploadControlsContainerClass = css`
	border: 2px dashed ${mainLight};
	padding: 1rem;
	transition: opacity 0.2s;
`;

const iconClass = css`
	text-align: center;
	margin-bottom: 0.75rem;
`;

const insideClass = css`
	margin: auto;
`;

const obscureClass = css`
	position: absolute;
	width: 100%;
	height: 100%;
	background: white;
	opacity: 0;
	top: 0;
	left: 0;
	cursor: pointer;
`;

export type FileFieldHandler = React.FC<{ storage: any; onChange: any }>;

type FileFieldProps = {
	name: string;
	fileFields?: FileFieldHandler;
	fileFieldsProps?: any;
	className?: string;
	instruction?: string | React.ReactNode;
	multiple?: boolean;
	accept?: AcceptFileType;
	noDragInstruction?: boolean;
};

export default function FileField({
	name,
	fileFields,
	fileFieldsProps,
	className,
	instruction,
	multiple,
	accept,
	noDragInstruction,
}: FileFieldProps) {
	const { setFieldValue, values = { [name]: [] } } = useFormikContext<any>();

	const files = values[name];
	const setFiles = React.useCallback(
		(prevValue: any) => setFieldValue(name, prevValue(files || [])),
		[files, name, setFieldValue],
	);

	const [fileFieldsPropsState] = React.useState(fileFieldsProps);

	const renderFileControls = React.useMemo(
		() =>
			files?.map((file: any, idx: number) => (
				<PreUploadFile
					key={idx}
					setFiles={setFiles}
					idx={idx}
					file={file}
					fileFields={fileFields}
					fileFieldsProps={fileFieldsPropsState}
				/>
			)),
		[files, setFiles, fileFields, fileFieldsPropsState],
	);

	const { dragLeave, dragOver, dropFiles, isCoverOn, openFileDialog, fileInputRef } =
		useDragDropFile({
			setFiles,
			accept,
		});

	const addFiles = React.useCallback(
		(e) => {
			const addedFiles: any = Array.from(e.target.files);
			setFiles((prevFiles: any) => [...prevFiles, ...addedFiles]);
		},
		[setFiles],
	);

	const acceptString = React.useMemo(() => acceptsToString(accept), [accept]);

	return (
		<div className={className}>
			{(multiple || (files || []).length < 1) && (
				<div
					className={cx(
						dragDropRootClass,
						uploadControlsContainerClass,
						isCoverOn ? draggedOverClass : null,
						className,
					)}
					onDragLeave={dragLeave}
					onDragOver={dragOver}
					onDrop={dropFiles}
				>
					<HiddenFileInput
						ref={fileInputRef}
						onChange={addFiles}
						multiple={multiple}
						accept={acceptString}
					/>
					<div className={obscureClass} onClick={openFileDialog} role="presentation" />
					<div className={insideClass}>
						<div className={iconClass}>
							<Upload htmlColor={mainLight} />
						</div>
						<div style={{ textAlign: 'center' }}>
							{!noDragInstruction && (
								<>
									Click or drag files here
									<br />
								</>
							)}
							{instruction}
						</div>
					</div>
				</div>
			)}
			<Box display="flex" flexWrap="wrap">
				{renderFileControls}
			</Box>
		</div>
	);
}
